import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { H6 } from '@collabodoc/component-library';
import useOnclickOutside from "react-cool-onclickoutside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from 'react-bootstrap';
import { ISSUE_STATUS } from "../../enums/patientcontactenums";
import { IssueContext } from "../../IssueContext";
import { FormattedMessage, useIntl } from "react-intl";

const StatusFilter = () => {
    const intl = useIntl();
    const theme = useContext(ThemeContext);

    const filterDivStyle = {
        backgroundColor: theme.colors.white,
        padding: "7px 0px",
        position: "absolute",
        marginTop: "5px",
        border: "1px solid rgba(0,0,0,.15)",
        borderRadius: ".25rem",
        zIndex: "1000"
    };

    const filterItemStyle = {
        padding: "0px 13px"
    };

    const STATUS_LABEL = {
        [ISSUE_STATUS.WAITING_ANSWER]: intl.formatMessage({ id: "issuetablerow.status.new" }),
        [ISSUE_STATUS.ANSWERED]: intl.formatMessage({ id: "issuetablerow.status.waiting" }),
        [ISSUE_STATUS.CHAT_CLOSED]: intl.formatMessage({ id: "issuetablerow.status.closed" })
    }
    const [filterDivOpen, setFilterDivOpen] = useState(false);
    const [filterIconFilled, setFilterIconFilled] = useState(true);
    const { statusFilters, setStatusFilters } = useContext(IssueContext);

    const toggleFilterDiv = (e) => {
        e.stopPropagation();
        setFilterDivOpen(!filterDivOpen);
    };

    const handleFiltering = ({ target }) => {
        let newFilters = []
        if (target.checked) {
            newFilters = statusFilters.concat([Number(target.name)])
        }
        else {
            newFilters = statusFilters.filter(s => s !== Number(target.name))
        }
        if (!newFilters.length) {
            newFilters = [ISSUE_STATUS.WAITING_ANSWER, ISSUE_STATUS.ANSWERED, ISSUE_STATUS.CHAT_CLOSED]
        }
        if (newFilters.length < 3) {
            setFilterIconFilled(false);
        }
        else {
            setFilterIconFilled(true);
        }
        setStatusFilters(newFilters)
    };

    const handleClickOutside = useOnclickOutside(() => {
        setFilterDivOpen(false);
    });

    return (
        <>
            <div ref={handleClickOutside}>
                <span onClick={toggleFilterDiv} className={"filterIcon"} >
                    Status {filterIconFilled ?
                        (<FontAwesomeIcon icon={"filter"} />) :
                        (<FontAwesomeIcon icon={"filter"} style={{ color: theme.colors.secondary }} />)}
                </span>
                {filterDivOpen && (
                    <div style={filterDivStyle} id="filter">
                        <H6 style={filterItemStyle}> <FormattedMessage id={"issuetablerow.status.filter"} />: </H6>
                        {Object.entries(STATUS_LABEL).map(([status, label]) =>
                            <span style={filterItemStyle} className={"dropdown-item status-dropdown"}>
                                <Form.Check type="checkbox"
                                    checked={statusFilters.includes(Number(status))}
                                    onChange={handleFiltering}
                                    name={status}
                                    key={status}
                                    label={label} />
                            </span>)}
                    </div >)}
            </div>
        </>
    );
}
export default StatusFilter;
