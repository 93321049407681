import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  TextAreaWithLabelAndError,
  usePut,
} from "@collabodoc/component-library";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { API_URLS } from "../../enums/urls";

const IssueDetailsCard = ({ issue }) => {
  const [comment, setComment] = useState(issue.comment);
  const { accessToken } = useOidcAccessToken();
  const { careCenterId } = useParams();
  const { doPut: updateComment, isLoading } = usePut(
    API_URLS.ISSUE_COMMENT(careCenterId, issue.issueId),
    accessToken
  );

  return (
    <Card>
      <Card.Header>
        <h4 className="dispInline">
          <FormattedMessage id={"issuedetailscard.header"} />
        </h4>
      </Card.Header>
      <Card.Body>
        <label className="question" htmlFor={"comment"}>
          <FormattedMessage id={"comment"} />
        </label>
        <TextAreaWithLabelAndError
          value={comment || ""}
          disabled={isLoading || issue.isClosed}
          handleBlur={() => updateComment({ commentText: comment })}
          handleChange={(text) => setComment(text)}
          id={"comment"}
          className="form-control"
          minRows={3}
          maxRows={5}
        />
      </Card.Body>
    </Card>
  );
};
export default IssueDetailsCard;
