import { useOidc } from "@axa-fr/react-oidc";
import { LoadingView, NavBar } from "@collabodoc/component-library";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../Content/Images/Logo/collabodoc_light.png";
import { EXTERNAL_URLS, VIEWS } from "../enums/urls";
import { GlobalContext } from "../views/GlobalContext";

const NavMenu = () => {
  const { careCenters, isLoadingCareCenters } = useContext(GlobalContext);

  const { logout } = useOidc();
  const intl = useIntl();
  const navigate = useNavigate();
  const { careCenterId } = useParams();

  const handleChange = (id) => {
    navigate(`${VIEWS.CARECENTERS}/${id}`);
  };

  const handleLogOut = () => {
    logout();
  };

  const handleLogoClick = () => {
    if (EXTERNAL_URLS.CAREGIVER_PORTAL) {
      window.location.href = `https://${EXTERNAL_URLS.CAREGIVER_PORTAL}`;
    } else if (careCenterId) {
      navigate(`${VIEWS.CARECENTERS}/${careCenterId}`);
    } else {
      navigate(VIEWS.CARECENTERS);
    }
  };

  const items = [
    {
      label: intl.formatMessage({ id: "nav.myaccount" }),
      url: `${EXTERNAL_URLS.MANAGE}?returnUrl=${window.location.href}`,
    },
    {
      label: intl.formatMessage({ id: "logout" }),
      handler: handleLogOut,
    },
  ];

  if (isLoadingCareCenters) {
    return <LoadingView />;
  }

  let dropdown = null;
  if (careCenters && careCenters.length > 1 && careCenterId) {
    dropdown = {
      selected: careCenters.find((cc) => cc.id.toString() === careCenterId)
        .name,
      dropdownitems: careCenters.map((cc) => ({
        label: cc.name,
        id: cc.id,
        handler: () => handleChange(cc.id),
      })),
    };
  }

  return (
    <NavBar
      logo={logo}
      logohandler={handleLogoClick}
      items={items}
      dropdown={dropdown}
    />
  );
};

export default NavMenu;
