import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  DangerButton,
  H2,
  SecondaryButton,
  useDelete,
} from "@collabodoc/component-library";
import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { API_URLS } from "../../enums/urls";

export const DeleteModal = ({
  issue,
  toggleDeleteModal,
  showDeleteModal,
  hideDeleteModal,
}) => {
  const { accessToken } = useOidcAccessToken();
  const { careCenterId } = useParams();
  const { doDelete } = useDelete(
    API_URLS.ISSUE(careCenterId, issue.issueId),
    accessToken
  );

  const handleDelete = (e) => {
    e.stopPropagation();
    doDelete().then(() => {
      toggleDeleteModal();
    });
  };

  return (
    <Modal show={showDeleteModal} onHide={hideDeleteModal}>
      <Modal.Header>
        <H2>
          <FormattedMessage id="deletemodal.header" />
        </H2>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage
          id="deletemodal.body"
          values={{
            firstName: issue.issueOwner.firstName,
            lastname: issue.issueOwner.lastName,
          }}
        />
        <br />
        <br />
        <strong>
          <FormattedMessage id="deletemodal.text" />
        </strong>
      </Modal.Body>
      <Modal.Footer>
        <SecondaryButton
          type={"button"}
          className={"floatRight"}
          onClick={toggleDeleteModal}
        >
          <FormattedMessage id="abort" />
        </SecondaryButton>
        <DangerButton className={"floatRight"} onClick={handleDelete}>
          <FormattedMessage id="deletemodal.confirmdelete" />
        </DangerButton>
      </Modal.Footer>
    </Modal>
  );
};
