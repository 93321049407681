import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { CheckboxWithLabel, usePut } from "@collabodoc/component-library";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { API_URLS } from "../../enums/urls";

const CheckboxWrapper = styled.div`
  text-align: left;
  margin-bottom: 0.5rem;
`;

const AllowPatientFileUpload = ({ issue }) => {
  const { accessToken } = useOidcAccessToken();
  const [allowPatientFileUpload, setAllowPatientFileUpload] = useState(
    issue.allowPatientFileUpload
  );
  const { careCenterId } = useParams();
  const { doPut: saveAllowPatientFileUpload } = usePut(
    API_URLS.ISSUE_SET_ALLOW_FILE_UPLOAD(careCenterId, issue.issueId),
    accessToken
  );

  const handleAllowPatientFileUploadToggle = () => {
    const newValue = !allowPatientFileUpload;
    saveAllowPatientFileUpload({ allowPatientFileUpload: newValue });
    setAllowPatientFileUpload(newValue);
  };

  return (
    <CheckboxWrapper>
      <CheckboxWithLabel
        label={"Patienten har möjlighet att bifoga filer"}
        onChange={handleAllowPatientFileUploadToggle}
        disabled={issue.isClosed}
        defaultChecked={allowPatientFileUpload}
      />
    </CheckboxWrapper>
  );
};

export default AllowPatientFileUpload;
