const PATIENT_CONTACT_BACKEND_API = `${window.env.REACT_APP_PATIENT_CONTACT_API_URL}/api`;

const API_URLS = {
  ISSUES: (careCenterId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/issues`,
  ISSUE_CREATE: (careCenterId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/issues`,
  ISSUE: (careCenterId, issueId, override) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/issues/${issueId}?overrideOpenedBy=${override}`,
  ISSUE_RESET_OPEN: (careCenterId, issueId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/issues/${issueId}/resetopen`,
  ISSUE_REACTIVATE: (careCenterId, issueId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/issues/${issueId}/reactivate`,
  ISSUE_CLOSE_CHAT: (careCenterId, issueId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/issues/${issueId}/closechat`,
  ISSUE_TREATMENT_USERS: (careCenterId, issueId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/issues/${issueId}/treatmentusers`,
  ISSUE_CLOSED: (careCenterId, issueId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/issues/${issueId}/close`,
  ISSUE_MESSAGES: (careCenterId, issueId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/issues/${issueId}/messages/`,
  ISSUE_ASSESSMENT: (careCenterId, issueId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/issues/${issueId}/assessment`,
  ISSUE_ANAMNESIS: (careCenterId, issueId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/issues/${issueId}/anamnesis`,
  ISSUE_COMMENT: (careCenterId, issueId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/issues/${issueId}/comment`,
  ISSUE_MESSAGE_VIDEOLINK: (careCenterId, issueId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/issues/${issueId}/messages/videomeeting`,
  ISSUE_SIGNALR_HUB: () =>
    `${window.env.REACT_APP_PATIENT_CONTACT_API_URL}/issuehub`,
  ISSUE_SET_ALLOW_FILE_UPLOAD: (careCenterId, issueId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/issues/${issueId}/allowpatientfileupload`,
  ISSUE_DOWNLOAD_FILE: (careCenterId, fileId, issueId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/issues/attachment?fileId=${fileId}&issueId=${issueId}`,
  USERS: (careCenterId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/users`,
  PATIENT_LOOKUP: (careCenterId) =>
    `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters/${careCenterId}/person`,
  CARECENTERS: `${PATIENT_CONTACT_BACKEND_API}/caregiver/carecenters`,
  CONTACTREASONS: `${PATIENT_CONTACT_BACKEND_API}/contactreasons`,
  STATISTICS: (careCenterId, startdate, enddate) => {
    if (!startdate && !enddate) {
      return `${PATIENT_CONTACT_BACKEND_API}/caregiver/statistics?carecenterid=${careCenterId}`;
    }
    return `${PATIENT_CONTACT_BACKEND_API}/caregiver/statistics?carecenterid=${careCenterId}&&startdate=${startdate}&&enddate=${enddate}`;
  },
};

const VIEWS = {
  CARECENTERS: "carecenters",
  CARECENTER: ":careCenterId",
  ISSUES: "issues",
  ISSUE: ":issueId",
  CREATE_NEW_ISSUE: "new",
  VIDEO: "video",
  STATISTICS: "statistics",
};

const EXTERNAL_URLS = {
  USERS: `${window.env.authority}/user`,
  MANAGE: `${window.env.authority}/manage`,
  CAREGIVER_PORTAL: window.env.REACT_APP_PORTAL_URL,
};

export { VIEWS, API_URLS, EXTERNAL_URLS };
