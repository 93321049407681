import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import {
  getRequestInit,
  LoadingView,
  useFetchy,
} from "@collabodoc/component-library";
import React, { createContext } from "react";
import { useParams } from "react-router-dom";
import { API_URLS } from "../enums/urls";

export const GlobalContext = createContext({});

export const GlobalContextProvider = ({ children }) => {
  const { careCenterId } = useParams();
  const { accessToken } = useOidcAccessToken();
  const { oidcUser } = useOidcUser();
  const { data: contactReasons = [] } = useFetchy(
    API_URLS.CONTACTREASONS,
    getRequestInit({ accessToken })
  );
  const { data: careCenters, isLoading: isLoadingCareCenters } = useFetchy(
    API_URLS.CARECENTERS,
    getRequestInit({ accessToken })
  );
  const currentCareCenter = careCenters?.find(
    (c) => c.careCenterId === careCenterId
  );
  const getContactReasonName = (id) => {
    const contactReason =
      contactReasons &&
      contactReasons.find((contactReason) => contactReason.id === +id);
    return contactReason && contactReason.name;
  };

  if (!careCenters || !oidcUser) {
    return <LoadingView />;
  }

  return (
    <GlobalContext.Provider
      value={{
        careCenters: careCenters || [],
        isLoadingCareCenters,
        currentCareCenter,
        contactReasons,
        getContactReasonName,
        oidcUser,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
