import React from "react";
import {
  H2,
  SecondaryButton,
  PrimaryButton,
} from "@collabodoc/component-library";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export const IsOpenedModal = ({
  issue,
  toggleIsOpenedModal,
  showIsOpenedModal,
  hideIsOpenedModal,
  handleOpen,
}) => {
  return (
    <Modal show={showIsOpenedModal} onHide={hideIsOpenedModal}>
      <Modal.Header>
        <H2>
          <FormattedMessage id="isopenedmodal.header" />
        </H2>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage
          id="isopenedmodal.body"
          values={{
            openedbyname: issue.openedByName,
          }}
        />
        <br />
        <br />
      </Modal.Body>
      <Modal.Footer>
        <SecondaryButton
          type={"button"}
          className={"floatRight"}
          onClick={toggleIsOpenedModal}
        >
          <FormattedMessage id="abort" />
        </SecondaryButton>
        <PrimaryButton
          type={"button"}
          className={"floatRight"}
          onClick={handleOpen}
        >
          <FormattedMessage id="isopenedmodal.confirmoverride" />
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};
