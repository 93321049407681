import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  SecondaryButton,
  TextAreaWithLabelAndError,
  usePut,
} from "@collabodoc/component-library";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { SENDER } from "../../enums/patientcontactenums";
import { API_URLS } from "../../enums/urls";

const AssessmentCard = ({ issue = {} }) => {
  const intl = useIntl();
  const { accessToken } = useOidcAccessToken();
  const [assessment, setAssessment] = useState(issue && issue.assessment);
  const { careCenterId } = useParams();
  const { doPut: saveAssessment } = usePut(
    API_URLS.ISSUE_ASSESSMENT(careCenterId, issue && issue.issueId),
    accessToken
  );
  const handleAssessmentChange = (text) => setAssessment(text);
  const [anamnesis, setAnamnesis] = useState(issue && issue.anamnesis);
  const { doPut: saveAnamnesis } = usePut(
    API_URLS.ISSUE_ANAMNESIS(careCenterId, issue && issue.issueId),
    accessToken
  );
  const handleAnamnesisChange = (text) => setAnamnesis(text);
  const firstMessage = issue.messages
    .filter((m) => m.senderEnum === SENDER.PATIENT)[0]
    ?.messageText.replace(/(^ {2,})/gm, "");

  const handleAssessmentBlur = () => {
    saveAssessment({ messageText: assessment });
  };

  const handleAnamnesisBlur = () => {
    saveAnamnesis({ anamnesisText: anamnesis });
  };

  const insertPatientAnamnesis = () => {
    let newAnamnesisText = "";
    if (anamnesisRef.current.value.trim() !== "") {
      newAnamnesisText = anamnesisRef.current.value + "\n\n";
    }
    newAnamnesisText += firstMessage;
    setAnamnesis(newAnamnesisText);
    saveAnamnesis({ anamnesisText: newAnamnesisText });
  };

  const anamnesisRef = useRef();
  const assessmentRef = useRef();

  const makeCopyText = async () => {
    const anamnesis = anamnesisRef.current.value;
    const assessment = assessmentRef.current.value;
    let result = `${issue.contactReason.name}\n\n`;
    if (anamnesis.trim()) {
      result +=
        (anamnesis &&
          intl.formatMessage({ id: "assessmentcard.anamnesis" }) + ":\n") +
        anamnesis;
      if (assessment) {
        result += "\n\n";
      }
    }
    if (assessment.trim()) {
      result +=
        intl.formatMessage({ id: "assessmentcard.assessment" }) +
        ":\n" +
        assessment;
    }
    await navigator.clipboard.writeText(result);
  };

  return (
    <Card>
      <Card.Header>
        <h4 className="dispInline">
          <FormattedMessage id={"assessmentcard.textojournal"} />
        </h4>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col s={12} xs={12}>
            <Row>
              <Col s={12} xs={12}>
                <TextAreaWithLabelAndError
                  autoFocus={false}
                  value={issue.contactReason.name}
                  minRows={1}
                  maxRows={1}
                  id={"contactReasonTextBox"}
                  disabled={true}
                  whiteTextArea={true}
                  label={intl.formatMessage({ id: "contactreason" })}
                />
                <TextAreaWithLabelAndError
                  autoFocus={false}
                  value={anamnesis || ""}
                  ref={anamnesisRef}
                  minRows={5}
                  maxRows={10}
                  id={"anamnesisTextBox"}
                  handleChange={handleAnamnesisChange}
                  handleBlur={handleAnamnesisBlur}
                  disabled={issue.isClosed}
                  whiteTextArea={true}
                  label={intl.formatMessage({ id: "assessmentcard.anamnesis" })}
                />
              </Col>
              <Col s={12} xs={12} className="text-right">
                <SecondaryButton
                  type={"button"}
                  disabled={!firstMessage}
                  onClick={() => insertPatientAnamnesis()}
                >
                  Lägg in patientens berättelse
                </SecondaryButton>
              </Col>
            </Row>
            <br />
            <TextAreaWithLabelAndError
              value={assessment || ""}
              ref={assessmentRef}
              minRows={5}
              handleChange={handleAssessmentChange}
              handleBlur={handleAssessmentBlur}
              label={intl.formatMessage({ id: "assessmentcard.assessment" })}
              disabled={issue.isClosed}
              autoFocus={false}
            />
            <Card.Footer className={"text-right"}>
              <SecondaryButton type={"button"} onClick={makeCopyText}>
                <FontAwesomeIcon icon={faCopy} />
                <span>
                  {intl.formatMessage({ id: "assessmentcard.copybutton" })}
                </span>
              </SecondaryButton>
            </Card.Footer>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default AssessmentCard;
