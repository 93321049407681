import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  LoadingView,
  PrimaryButton,
  TextAreaWithLabelAndError,
  usePostForm,
} from "@collabodoc/component-library";
import { faCheck, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTour } from "@reactour/tour";
import Cookies from "js-cookie";
import properNameCase from "proper-name-case";
import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import FileUpload from "../../components/FileUpload";
import { TUTORIAL_COOKIES } from "../../enums/tutorial-cookies";
import { API_URLS } from "../../enums/urls";
import { getCalendarDateFormat } from "../../utils/dateUtils";
import { useRoles } from "../../utils/roleUtils";
import { GlobalContext } from "../GlobalContext";

const ReplyCard = ({ issue, reFetchIssue }) => {
  const [messageText, setMessageText] = useState();
  const { careCenterId } = useParams();
  const intl = useIntl();
  const { getClinicRoleName } = useRoles();
  const [loadingReplyCard, setLoadingReplyCard] = useState(false);
  const { oidcUser } = useContext(GlobalContext);
  const { accessToken } = useOidcAccessToken();
  const userFirstName = properNameCase.convert(oidcUser.firstname);
  const userLastName = properNameCase.convert(oidcUser.lastname);
  const userRole = getClinicRoleName(oidcUser.role);
  const [files, setFiles] = useState([]);
  const { setIsOpen } = useTour();

  useEffect(() => {
    const enableTour = false;
    if (enableTour === true) {
      const cookie = Cookies.get(TUTORIAL_COOKIES.LATEST);
      if (cookie !== "true") {
        setIsOpen(true);
      }
    }
  });

  const handleMessageChange = (text) => setMessageText(text);

  const { doPostForm: sendMessage } = usePostForm(
    API_URLS.ISSUE_MESSAGES(careCenterId, issue && issue.issueId),
    accessToken
  );

  const handleSend = async (closeChat) => {
    setLoadingReplyCard(true);

    const data = new FormData();
    files.forEach((file) => {
      data.append("attachments", file);
    });
    data.append("messageText", messageText);
    data.append("closeChat", closeChat);
    await sendMessage(data)
      .then(() => reFetchIssue())
      .then(() => setMessageText())
      .then(() => setFiles([]))
      .then(() => setLoadingReplyCard(false));
  };

  return (
    <Card.Body>
      {loadingReplyCard && <LoadingView />}
      <div className={loadingReplyCard ? "loading" : ""}>
        {!issue.isClosed && !issue.isChatClosed && (
          <>
            <Row>
              <Col s={12} xs={12}>
                <TextAreaWithLabelAndError
                  autoFocus={false}
                  value={
                    !messageText
                      ? `\n${intl.formatMessage({
                          id: "regards",
                        })},\n${userFirstName} ${userLastName}${
                          userRole && ", " + userRole
                        }`
                      : messageText
                  }
                  minRows={5}
                  maxRows={10}
                  id={"replycard"}
                  disabled={issue.isClosed}
                  whiteTextArea={false}
                  handleChange={handleMessageChange}
                />
              </Col>
              <br />
              <br />
            </Row>
            <Row>
              <Col>
                <FileUpload files={files} setFiles={setFiles} />
              </Col>
            </Row>
          </>
        )}
        <Card.Footer className={"text-right"}>
          {issue.isChatClosed && (
            <div className={"text-left"}>
              <FontAwesomeIcon icon={faCheck} size="sm" />
              {` ${intl.formatMessage({
                id: "replycard.dialogueclosed",
              })} ${getCalendarDateFormat(
                issue.timeOfChatClosed
              ).toLowerCase()}`}
            </div>
          )}
          {!issue.isClosed && !issue.isChatClosed && (
            <PrimaryButton
              type={"button"}
              className={"rightMargin-sm"}
              disabled={!messageText}
              onClick={() => handleSend(false)}
            >
              <FontAwesomeIcon icon={faPaperPlane} size="sm" />
              <span>{intl.formatMessage({ id: "replycard.sendandwait" })}</span>
            </PrimaryButton>
          )}
          {!issue.isClosed && !issue.isChatClosed && (
            <PrimaryButton
              type={"button"}
              disabled={!messageText}
              onClick={() => handleSend(true)}
            >
              <FontAwesomeIcon icon={faCheck} size="sm" />
              <span>
                {" "}
                {intl.formatMessage({ id: "replycard.sendandclose" })}
              </span>
            </PrimaryButton>
          )}
        </Card.Footer>
      </div>
    </Card.Body>
  );
};
export default ReplyCard;
