import moment from "moment";
import 'moment/locale/sv';
import 'moment/locale/nb';
import {LOCALE} from "./localeUtils";

const setMomentLocale = (intl) => {
    moment.updateLocale(LOCALE, {
        calendar: {
            lastDay: intl.formatMessage({id: "calendar.lastDay"}),
            sameDay: intl.formatMessage({id: "calendar.sameDay"}),
            nextDay: intl.formatMessage({id: "calendar.nextDay"}),
            lastWeek: intl.formatMessage({id: "calendar.lastWeek"}),
            nextWeek: intl.formatMessage({id: "calendar.nextWeek"}),
            sameElse: intl.formatMessage({id: "calendar.sameElse"})
        }
    });
    moment.locale(LOCALE)
}

const getCalendarDateFormat = (date) => moment.utc(date).local().calendar();

const getTimeSince = (date) => moment.utc(date).local().fromNow();

export {setMomentLocale, getCalendarDateFormat, getTimeSince}