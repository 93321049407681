import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { reverse } from "named-urls";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import "tippy.js/dist/tippy.css";
import { TableCell, TableRow } from "../../components/Table";
import YellowLetter from "../../Content/Images/Icons/Blue_Message.svg";
import Check from "../../Content/Images/Icons/Check.svg";
import Waiting from "../../Content/Images/Icons/Waiting_Whole.svg";
import {
  ISSUE_STATUS,
  ISSUE_TABLE_TYPE,
  SENDER,
} from "../../enums/patientcontactenums";
import { VIEWS } from "../../enums/urls";
import { getTimeSince } from "../../utils/dateUtils";
import { getRoleIcon } from "../../utils/roleUtils";
import { GlobalContext } from "../GlobalContext";
import CommentModal from "./CommentModal";
import { DropDownMenu } from "./DropDownMenu";
import { IsOpenedModal } from "./IsOpenedModal";

const GetStatusIcon = (status) => {
  switch (status) {
    case ISSUE_STATUS.CHAT_CLOSED:
      return Check;
    case ISSUE_STATUS.WAITING_ANSWER:
      return YellowLetter;
    case ISSUE_STATUS.ANSWERED:
      return Waiting;
    default:
      return;
  }
};

const GetStatusTextKey = (status) => {
  switch (status) {
    case ISSUE_STATUS.CHAT_CLOSED:
      return "issuetablerow.status.closed";
    case ISSUE_STATUS.WAITING_ANSWER:
      return "issuetablerow.status.new";
    case ISSUE_STATUS.ANSWERED:
      return "issuetablerow.status.waiting";
    default:
  }
};

const IssueTableRow = ({ issue, tableType }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { oidcUser } = useContext(GlobalContext);
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showIsOpenedModal, setShowIsOpenedModal] = useState(false);
  const latestMessage = issue && issue.messages[issue.messages.length - 1];

  const handleRowClick = (e) => {
    e.preventDefault();
    if (issue.isOpened && issue.openedByUserId !== oidcUser.sub) {
      setShowIsOpenedModal(true);
    } else {
      e.preventDefault();
      navigate(reverse(VIEWS.ISSUE, { issueId: issue.issueId }));
    }
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setDropDownOpen(!dropdownOpen);
  };

  const getLatestInitials = (issue) => {
    const messagesFromCaregiver = issue.messages.filter(
      (m) => m.senderEnum === SENDER.CAREGIVER
    );
    if (!messagesFromCaregiver || messagesFromCaregiver.length === 0)
      return null;
    const latestMessage =
      messagesFromCaregiver[messagesFromCaregiver.length - 1];
    let lastSenderInitials =
      latestMessage &&
      latestMessage.senderName
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "");
    if (latestMessage.senderId === oidcUser.sub && lastSenderInitials !== "") {
      return <div className="currentUserInitials">{lastSenderInitials}</div>;
    }
    return lastSenderInitials;
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
    if (!showCommentModal) {
      setShowCommentModal(!showCommentModal);
    }
  };

  const handleOpen = (e) => {
    e.preventDefault();
    navigate(
      `${reverse(VIEWS.ISSUE, { issueId: issue.issueId })}?override=true`
    );
  };

  return (
    <>
      <IsOpenedModal
        issue={issue}
        toggleIsOpenedModal={() => setShowIsOpenedModal(!showIsOpenedModal)}
        showIsOpenedModal={showIsOpenedModal}
        hideIsOpenedModal={() => setShowIsOpenedModal(false)}
        handleOpen={handleOpen}
      />
      <TableRow
        key={issue.IssueId}
        data-issueid={issue.issueId}
        onClick={handleRowClick}
      >
        <TableCell onClick={(e) => e.stopPropagation()}>
          <DropDownMenu
            issue={issue}
            showMenu={dropdownOpen}
            tableType={tableType}
            toggleMenu={toggleDropdown}
            setOpen={setDropDownOpen}
            handleRowClick={handleRowClick}
          />
        </TableCell>
        <TableCell data-title="Name">
          {issue.issueOwner.firstName} {issue.issueOwner.lastName}
        </TableCell>
        <TableCell data-title="Age" className="text-right">
          {issue.issueOwner.ageInYears !== 0
            ? `${issue.issueOwner.ageInYears} ${intl.formatMessage({
                id: "year",
              })}`
            : `${issue.issueOwner.ageInMonths} ${intl.formatMessage({
                id: "month",
              })}`}
        </TableCell>
        <TableCell
          data-title="Gender"
          className="d-none d-lg-table-cell text-center"
        >
          <span
            className={
              issue.issueOwner.gender === 0 ? "fa fa-mars" : "fa fa-venus"
            }
          />
        </TableCell>
        <TableCell className="d-none d-lg-table-cell">
          {issue.contactReason.name}
        </TableCell>
        <TableCell
          data-title="TimeOfRegistration"
          className="d-none d-lg-table-cell text-left"
        >
          {getTimeSince(latestMessage.timeOfCreated)}
        </TableCell>
        <TableCell
          data-title="OpenedBy"
          className="d-none d-lg-table-cell text-left"
        >
          <img alt="" src={getRoleIcon(issue.openedByRole)} width="28px" />{" "}
          {issue.openedByName}
        </TableCell>
        {tableType === ISSUE_TABLE_TYPE.ACTIVE && (
          <>
            <TableCell className="text-left">
              <img alt="" src={GetStatusIcon(issue.issueStatus)} width="28px" />{" "}
              {intl.formatMessage({ id: GetStatusTextKey(issue.issueStatus) })}
            </TableCell>
            <TableCell className="d-none d-lg-table-cell text-left">
              {getLatestInitials(issue)}
            </TableCell>
            {issue.comment ? (
              <Tippy
                theme="tippy"
                content={issue.comment}
                placement="left"
                animation="scale-subtle"
                duration={0}
              >
                <TableCell
                  width="350px"
                  className="overflowText"
                  onClick={handleInputClick}
                >
                  <FontAwesomeIcon icon={faPencilAlt} /> {issue.comment}
                  <CommentModal
                    issue={issue}
                    toggleCommentModal={() =>
                      setShowCommentModal(!showCommentModal)
                    }
                    showCommentModal={showCommentModal}
                    hideCommentModal={() => setShowCommentModal(false)}
                  />
                </TableCell>
              </Tippy>
            ) : (
              <TableCell
                width="350px"
                className="overflowText"
                onClick={handleInputClick}
              >
                <FontAwesomeIcon icon={faPencilAlt} /> {issue.comment}
                <CommentModal
                  issue={issue}
                  toggleCommentModal={() =>
                    setShowCommentModal(!showCommentModal)
                  }
                  showCommentModal={showCommentModal}
                  hideCommentModal={() => setShowCommentModal(false)}
                />
              </TableCell>
            )}
          </>
        )}
      </TableRow>
    </>
  );
};
export default IssueTableRow;
