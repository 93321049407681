export const Weekdays = {
    Monday: "Måndag",
    Tuesday: "Tisdag",
    Wednesday: "Onsdag",
    Thursday: "Torsdag",
    Friday: "Fredag",
    Saturday: "Lördag",
    Sunday: "Söndag"
}

export const WeekdayOrder = {
    Måndag: 0,
    Tisdag: 1,
    Onsdag: 2,
    Torsdag: 3,
    Fredag: 4,
    Lördag: 5,
    Söndag: 6
}

export const Periods = {
    Custom: 'custom',
    All: 'all'
}

export const IsChild = {
    Other: "Barn",
    Self: "Vuxen"
}

export const Gender = {
    Male: "Man",
    Female: "Kvinna"
}

export const StatisticsView = {
    Issues_1: "issues_1",
    Issues_2: "issues_2",
    Patient: "patient",
    Users: "users"
}

export const StatisticsTheme = [
    "#0A4A7C",
    "#a0f0eb",
    "#ffe150",
    "#ff9f50",
    "#ff5050",
    "#A44200",
    "#e5989b",
    "#DE89BE",
    "#ce418e",
    "#843baa",
    "#6045af",
    "#547AA5",
    "#31959a",
    "#828E82",
    "#c0ee65",
    "#93FF96",
    "#40cc40",
    "#40916c",
    "#1b4332",
  ];