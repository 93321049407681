import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  H2,
  SecondaryButton,
  SuccessButton,
  usePut,
} from "@collabodoc/component-library";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { SENDER } from "../../enums/patientcontactenums";
import { API_URLS } from "../../enums/urls";

const CloseIssueModal = ({
  issue,
  showCloseIssueModal,
  hideCloseIssueModal,
  toggleCloseissueModal,
}) => {
  const navigate = useNavigate();

  const intl = useIntl();
  const { accessToken } = useOidcAccessToken();
  const { careCenterId } = useParams();
  const latestMessage = issue && issue.messages[issue.messages.length - 1];
  const { doPut: closeIssue } = usePut(
    API_URLS.ISSUE_CLOSED(careCenterId, issue.issueId),
    accessToken
  );
  const handleClick = (e) => {
    e.preventDefault();
    // TODO: Error handling
    closeIssue().then(() => navigate(".."));
  };

  return (
    <Modal show={showCloseIssueModal} onHide={hideCloseIssueModal}>
      <Modal.Header>
        <H2>{intl.formatMessage({ id: "closeissuemodal.title" })}</H2>
      </Modal.Header>
      <Modal.Body>
        <>
          {intl.formatMessage({ id: "closeissuemodal.havetransfered" })}
          <strong>
            {` ${issue.issueOwner.firstName} ${issue.issueOwner.lastName} (${issue.issueOwner.personalNumber})`}
            &nbsp;
          </strong>
          {intl.formatMessage({ id: "closeissuemodal.tojournalsystem" })}
          {latestMessage.senderEnum === SENDER.PATIENT && (
            <>
              <br />
              <br />
              <FontAwesomeIcon
                className={"warning"}
                icon={faExclamationTriangle}
              />
              &nbsp;
              <strong>
                {intl.formatMessage({ id: "closeissuemodal.noanswer" })}
              </strong>
            </>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <SecondaryButton
          type={"button"}
          className={"floatRight"}
          onClick={toggleCloseissueModal}
        >
          {intl.formatMessage({ id: "closeissuemodal.cancelbtn" })}
        </SecondaryButton>
        <SuccessButton
          type={"button"}
          className={"floatRight"}
          onClick={handleClick}
        >
          {intl.formatMessage({ id: "closeissuemodal.okbtn" })}
        </SuccessButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CloseIssueModal;
