import styled from "styled-components";

const TableRow = styled.tr`
  height: 3rem;
`;

const TableCell = styled.td`
  vertical-align: middle !important;
`

export {TableRow, TableCell};
