import React from "react";
import { ISSUE_TABLE_TYPE } from "../../enums/patientcontactenums";
import IssueTableRow from "./IssueTableRow";
import StatusFilter from "./StatusFilter";
import { FormattedMessage } from "react-intl";

const IssueTable = ({ issues, tableType }) => (
  <table className="table table-striped table-hover">
    <thead>
      <tr>
        <th>
          <FormattedMessage id="issuetable.headercolumn.action" />
        </th>
        <th>
          <FormattedMessage id="issuetable.headercolumn.name" />
        </th>
        <th className="text-right">
          <FormattedMessage id="issuetable.headercolumn.age" />
        </th>
        <th className="d-none d-lg-table-cell text-center">
          <FormattedMessage id="issuetable.headercolumn.gender" />
        </th>
        <th className="d-none d-lg-table-cell">
          <FormattedMessage id="contactreason" />
        </th>
        <th className="d-none d-lg-table-cell text-left">
          <FormattedMessage id="issuetable.headercolumn.lastactivity" />
        </th>
        <th className="d-none d-lg-table-cell text-left">
          <FormattedMessage id="issuetable.headercolumn.openedby" />
        </th>
        {tableType === ISSUE_TABLE_TYPE.ACTIVE && (
          <>
            <th className="text-left">
              <StatusFilter />
            </th>
            <th className="d-none d-lg-table-cell">
              <FormattedMessage id="issuetable.headercolumn.repliedby" />
            </th>
            <th>
              <FormattedMessage id="issuetable.headercolumn.comment" />
            </th>
          </>
        )}
      </tr>
    </thead>
    <tbody>
      {issues.map((issue) => (
        <IssueTableRow
          key={issue.issueId}
          issue={issue}
          tableType={tableType}
        />
      ))}
    </tbody>
  </table>
);

export default IssueTable;
