import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  CheckboxWithLabel,
  DropDown,
  getRequestInit,
  H1,
  H3,
  H4,
  LoadingView,
  P,
  PrimaryButton,
  SecondaryButton,
  TextAreaWithLabelAndError,
  TextInputWithLabelAndError,
  useFetchy,
} from "@collabodoc/component-library";
import Personnummer from "personnummer";
import properNameCase from "proper-name-case";
import React, { useContext, useState } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import FileUpload from "../components/FileUpload";
import { API_URLS } from "../enums/urls";
import { useRoles } from "../utils/roleUtils";
import { GlobalContext } from "./GlobalContext";
import { CardBody, CardFooter, CardHeader } from "reactstrap";

const CHILD_MAX_AGE = 17;

const CreateNewIssueView = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [personalNumber, setPersonalNumber] = useState("");
  const [parentDataFromSpar, setParentDataFromSpar] = useState([]);
  const [parentFirstName, setParentFirstName] = useState("");
  const [parentLastName, setParentLastName] = useState("");
  const [parentPersonalNumber, setParentPersonalNumber] = useState("");
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [personalNumberError, setPersonalNumberError] = useState(null);
  const [parentFirstNameError, setParentFirstNameError] = useState(null);
  const [parentLastNameError, setParentLastNameError] = useState(null);
  const [parentPersonalNumberError, setParentPersonalNumberError] =
    useState(null);
  const [phoneValidationError, setPhoneValidationError] = useState(false);
  const [tempPhoneNumber, setTempPhoneNumber] = useState("");
  const [useParentContact, setUseParentContact] = useState(true);
  const [patientIsUnder18, setPatientIsUnder18] = useState(false);
  const [messageText, setMessageText] = useState();
  const [modal, setModal] = useState(false);
  const [contactReasonId, setContactReasonId] = useState();
  const [files, setFiles] = useState([]);
  const intl = useIntl();
  const { contactReasons, getContactReasonName } = useContext(GlobalContext);
  const { getClinicRoleName } = useRoles();
  const hasSparIntegration = window.env.enableSPARIntegration;

  const toggle = () => setModal(!modal);
  const { careCenterId } = useParams();
  const { oidcUser } = useContext(GlobalContext);
  const { accessToken } = useOidcAccessToken();
  const { doFetch: sendIssue, isLoading: loadingSend, response } = useFetchy();
  const { doFetch: lookupPerson, isLoading: loadingPerson } = useFetchy();

  const handleMessageChange = (text) => setMessageText(text);

  const userFirstName = properNameCase.convert(oidcUser.firstname);
  const userLastName = properNameCase.convert(oidcUser.lastname);
  const userRole = getClinicRoleName(oidcUser.role);

  const setPhoneNumber = (input) => {
    setTempPhoneNumber(input);
    if (input !== null) {
      const [isValid, formattedNumber] = validatePhoneNumber(input);
      setPhoneValidationError(!isValid);
      if (isValid) {
        setTempPhoneNumber(formattedNumber);
      }
    }
  };

  const validatePhoneNumber = (input) => {
    let tempPhone = input.toString();
    tempPhone = tempPhone.replace("-", "");
    if (tempPhone.substring(0, 2) === "07") {
      tempPhone = "+46" + tempPhone.substring(1, tempPhone.length);
    } else if (tempPhone.substring(0, 2) === "00") {
      tempPhone.replace(tempPhone.substring(0, 2), "+");
    }
    const re = new RegExp(
      "^(([0]{1}|[0]{2}[4][6]|[+]{1}[4][6])[7]{1}\\d{1}-?\\d{7})$"
    );
    if (re.test(tempPhone)) {
      return [true, tempPhone];
    }
    return [false, tempPhone];
  };

  const disabled =
    !firstName ||
    !lastName ||
    !personalNumber ||
    !tempPhoneNumber ||
    !messageText ||
    !contactReasonId;

  const handleFirstNameInput = (input) => {
    if (input) {
      setFirstName(input);
      setFirstNameError(null);
    } else {
      setFirstNameError(
        intl.formatMessage({ id: "creatnewissue.enterfirstname" })
      );
    }
  };

  const handleLastNameInput = (input) => {
    if (input) {
      setLastName(input);
      setLastNameError(null);
    } else {
      setLastNameError(
        intl.formatMessage({ id: "creatnewissue.enterlastname" })
      );
    }
  };

  const handlePersonalNumberInput = (input) => {
    setPersonalNumber(input);
    clearForm();
    if (!input || input.length < 12 || !Personnummer.valid(input)) {
      setPersonalNumberError(
        intl.formatMessage({ id: "createnewissue.entervalidpersonalnumber" })
      );
    } else {
      setPersonalNumberError(null);
      if (hasSparIntegration) {
        const body = {
          personalNumber: input,
        };
        const requestInit = getRequestInit({
          accessToken,
          method: "POST",
          data: body,
        });
        lookupPerson(API_URLS.PATIENT_LOOKUP(careCenterId), requestInit).then(
          ({ data, error }) => {
            if (error === undefined) {
              handleFirstNameInput(data?.firstName);
              handleLastNameInput(data?.lastName);
              setPersonalNumber(data?.personId || input);
              setParentDataFromSpar(data?.parents);
            }
          }
        );
      }
      if (Personnummer.parse(input).getAge() < CHILD_MAX_AGE) {
        setPatientIsUnder18(true);
      } else {
        setPatientIsUnder18(false);
      }
    }
  };

  const handleParentFirstNameInput = (input) => {
    if (input) {
      setParentFirstName(input);
      setParentFirstNameError(null);
    } else {
      setParentFirstName(null);
      setParentFirstNameError(
        intl.formatMessage({ id: "creatnewissue.enterfirstname" })
      );
    }
  };

  const handleParentLastNameInput = (input) => {
    if (input) {
      setParentLastName(input);
      setParentLastNameError(null);
    } else {
      setParentLastName(null);
      setParentLastNameError(
        intl.formatMessage({ id: "creatnewissue.enterlastname" })
      );
    }
  };

  const handleParentPersonalNumberInput = (input) => {
    setParentPersonalNumber(input);
    if (!input || input.length < 12 || !Personnummer.valid(input)) {
      setParentPersonalNumberError(
        intl.formatMessage({ id: "createnewissue.entervalidpersonalnumber" })
      );
    } else {
      setParentPersonalNumberError(null);
      if (hasSparIntegration) {
        const body = {
          personalNumber: input,
        };
        const requestInit = getRequestInit({
          accessToken,
          method: "POST",
          data: body,
        });
        lookupPerson(API_URLS.PATIENT_LOOKUP(careCenterId), requestInit).then(
          ({ data }) => {
            handleParentFirstNameInput(data?.firstName);
            handleParentLastNameInput(data?.lastName);
            setParentPersonalNumber(data?.personId || input);
          }
        );
      }
    }
  };

  const handleBack = () => {
    navigate("..");
  };

  const handleParentCheckbox = (event) => {
    setUseParentContact(!event.target.checked);
  };

  const handleSelectParent = (parent) => {
    setParentPersonalNumber(parent.personId);
    setParentFirstName(parent.firstName || "");
    setParentLastName(parent.lastName || "");
  };

  const handleClick = async () => {
    let parent;
    let child;
    if (useParentContact && patientIsUnder18) {
      parent = {
        personalNumber: parentPersonalNumber,
        firstName: parentFirstName,
        lastName: parentLastName,
        phoneNumber: tempPhoneNumber,
      };
      child = {
        personalNumber: personalNumber,
        firstName: firstName,
        lastName: lastName,
      };
    } else {
      parent = {
        personalNumber: personalNumber,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: tempPhoneNumber,
      };
    }
    await handleSend(parent, child);
  };

  const handleSend = async (parent, child) => {
    const data = new FormData();
    files.forEach((file) => {
      data.append("attachments", file);
    });
    data.append("PhoneNumber", parent.phoneNumber);
    data.append("MessageText", messageText);
    data.append("FirstName", properNameCase.convert(parent.firstName));
    data.append("LastName", properNameCase.convert(parent.lastName));
    data.append("PersonalNumber", parent.personalNumber);
    data.append("TimeOfRegistrationStart", new Date().toJSON());
    if (child) {
      data.append("ChildPersonalNumber", child.personalNumber);
      data.append("ChildFirstName", properNameCase.convert(child.firstName));
      data.append("ChildLastName", properNameCase.convert(child.lastName));
    }
    data.append("contactReasonId", contactReasonId);

    const requestInit = getRequestInit({ accessToken, method: "POST" });
    requestInit.body = data;
    sendIssue(API_URLS.ISSUE_CREATE(careCenterId), requestInit).then(
      (result) => {
        toggle();
        setFiles([]);
        if (result.response.ok) {
          setMessageText(null);
          setContactReasonId(null);
          setPersonalNumber("");
          clearForm();
        }
      }
    );
  };

  const options = contactReasons.map(({ id, name }) => ({
    text: name,
    id: id,
  }));

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setParentFirstName("");
    setParentLastName("");
    setParentPersonalNumber("");
    setTempPhoneNumber("");

    setFirstNameError(null);
    setLastNameError(null);
    setPersonalNumberError(null);
    setParentFirstNameError(null);
    setParentLastNameError(null);
    setParentPersonalNumberError(null);

    setParentDataFromSpar([]);
    setPatientIsUnder18(false);
  };

  return (
    <>
      <Row>
        <Col lg={{ span: 4, offset: 2 }}>
          <H1>
            <FormattedMessage id={"createnewissue.header"} />
          </H1>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 4, offset: 2 }}>
          <Card>
            <CardHeader>
              <H3 className={"topMargin-sm"}>Mottagare</H3>
            </CardHeader>
            <CardBody>
              <TextInputWithLabelAndError
                value={personalNumber || ""}
                id={"personalNumber"}
                label={intl.formatMessage({ id: "personalnumber" })}
                htmlFor={"personalNumber"}
                handleChange={handlePersonalNumberInput}
                required={true}
                placeholder={"ÅÅÅÅMMDD-XXXX"}
                type={"personalNumber"}
                validationError={
                  <b className="validationText">{personalNumberError}</b>
                }
              />

              <div className={"topMargin-md"}>
                <TextInputWithLabelAndError
                  value={firstName || ""}
                  id={"firstName"}
                  label={intl.formatMessage({ id: "firstname" })}
                  htmlFor={"firstName"}
                  handleChange={handleFirstNameInput}
                  required={true}
                  type={"firstName"}
                  validationError={
                    <b className="validationText">{firstNameError}</b>
                  }
                />
              </div>
              <div className={"topMargin-md"}>
                <TextInputWithLabelAndError
                  value={lastName || ""}
                  id={"lastName"}
                  label={intl.formatMessage({ id: "lastname" })}
                  htmlFor={"lastName"}
                  handleChange={handleLastNameInput}
                  required={true}
                  type={"lastName"}
                  validationError={
                    <b className="validationText">{lastNameError}</b>
                  }
                />
              </div>
              {patientIsUnder18 && (
                <>
                  <hr />
                  <H4>
                    {parentDataFromSpar ? (
                      <FormattedMessage
                        id={"createnewissue.chooseparentheader"}
                      />
                    ) : (
                      <FormattedMessage id={"createnewissue.parentheader"} />
                    )}
                  </H4>
                  <CheckboxWithLabel
                    defaultChecked={false}
                    onChange={handleParentCheckbox}
                    label={intl.formatMessage({
                      id: "createnewissue.donotenterparent",
                    })}
                  />
                </>
              )}
              {patientIsUnder18 && parentDataFromSpar && useParentContact && (
                <div className={"parentDataDiv topMargin-sm"}>
                  {parentDataFromSpar.map((p) => (
                    <div
                      onClick={() => handleSelectParent(p)}
                      className={
                        p.personId === parentPersonalNumber
                          ? "parentData selectedParent"
                          : "parentData"
                      }
                      key={p.personId}
                    >
                      <span>
                        {p?.firstName || "Förnamn saknas"}{" "}
                        {p?.lastName || "Efternamn saknas"}
                      </span>
                      <span>{p?.personId}</span>
                    </div>
                  ))}
                </div>
              )}
              {patientIsUnder18 && useParentContact && (
                <>
                  <div className={"topMargin-md"}>
                    <TextInputWithLabelAndError
                      id={"parentPersonalNumber"}
                      label={intl.formatMessage({
                        id: "createnewissue.parentpersonalnumber",
                      })}
                      htmlFor={"parentPersonalNumber"}
                      type={"parentPersonalNumber"}
                      value={parentPersonalNumber || ""}
                      handleChange={handleParentPersonalNumberInput}
                      required={true}
                      placeholder={"ÅÅÅÅMMDD-XXXX"}
                      validationError={
                        <b className="validationText">
                          {parentPersonalNumberError}
                        </b>
                      }
                    />
                  </div>
                  <div className={"topMargin-md"}>
                    <TextInputWithLabelAndError
                      value={parentFirstName}
                      id={"parentFirstName"}
                      label={intl.formatMessage({
                        id: "createnewissue.parentfirstname",
                      })}
                      htmlFor={"parentFirstName"}
                      type={"parentFirstName"}
                      handleChange={handleParentFirstNameInput}
                      required={true}
                      validationError={
                        <b className="validationText">{parentFirstNameError}</b>
                      }
                    />
                  </div>
                  <div className={"topMargin-md"}>
                    <TextInputWithLabelAndError
                      value={parentLastName}
                      id={"parentLastName"}
                      label={intl.formatMessage({
                        id: "createnewissue.parentlastname",
                      })}
                      htmlFor={"parentLastName"}
                      type={"parentLastName"}
                      handleChange={handleParentLastNameInput}
                      required={true}
                      validationError={
                        <b className="validationText">{parentLastNameError}</b>
                      }
                    />
                  </div>
                </>
              )}
              <div className={"topMargin-md"}>
                <TextInputWithLabelAndError
                  label={
                    patientIsUnder18 && useParentContact
                      ? intl.formatMessage({
                          id: "createnewissue.parentmobilenumber",
                        })
                      : intl.formatMessage({ id: "mobilenumber" })
                  }
                  htmlFor={"patientPhoneNumber"}
                  type={"tel"}
                  autoComplete={"tel"}
                  handleChange={setPhoneNumber}
                  value={tempPhoneNumber || ""}
                  required={true}
                  id={"patientPhoneNumber"}
                  validationError={
                    phoneValidationError ? (
                      <b className="validationText">
                        <FormattedMessage
                          id={"createnewissue.phonenumbervalidation"}
                        />
                        .
                      </b>
                    ) : (
                      <b> </b>
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={{ span: 4 }}>
          <Card>
            <CardHeader>
              <H3 className={"topMargin-sm"}>Ärende</H3>
            </CardHeader>
            <CardBody>
              <strong className="question">
                <FormattedMessage id={"createnewissue.contactreason"} />
                <span className="asterisk"> *</span>
              </strong>
              <DropDown
                placeholder={intl.formatMessage({
                  id: "createnewissue.choosecontactreason",
                })}
                selected={getContactReasonName(contactReasonId)}
                options={options}
                handler={(cr) => {
                  setContactReasonId(cr);
                }}
              />
              <div className={"topMargin-md"}>
                <TextAreaWithLabelAndError
                  required={true}
                  autoFocus={false}
                  value={
                    !messageText
                      ? `\n${intl.formatMessage({
                          id: "regards",
                        })},\n${userFirstName} ${userLastName} ${
                          userRole && ", " + userRole
                        }`
                      : messageText
                  }
                  minRows={5}
                  maxRows={10}
                  id={"messageNewIssue"}
                  handleChange={handleMessageChange}
                  label={intl.formatMessage({ id: "message" })}
                />
              </div>
              <Row>
                <Col>
                  <FileUpload files={files} setFiles={setFiles} />
                </Col>
              </Row>
              <CardFooter>
                <PrimaryButton
                  type={"button"}
                  className={"floatRight"}
                  disabled={disabled}
                  onClick={handleClick}
                >
                  <FormattedMessage id={"createnewissue.createissue"} />
                </PrimaryButton>
              </CardFooter>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <H3 className={"topMargin-sm"}>
                <FormattedMessage id={"createnewissue.createissueinfoheader"} />
              </H3>
            </CardHeader>
            <CardBody>
              <P>
                <FormattedMessage id={"createnewissue.createissueinfo"} />
              </P>
              <P>
                <FormattedMessage id={"createnewissue.createissueinfo2"} />
              </P>
            </CardBody>
          </Card>
          <SecondaryButton
            type={"button"}
            className={"floatRight"}
            onClick={handleBack}
          >
            <FormattedMessage id={"returntoissuelist"} />
          </SecondaryButton>
        </Col>
      </Row>

      <Modal show={modal} onHide={toggle}>
        <Modal.Body>
          {response?.ok ? (
            <p>
              <FormattedMessage id={"createnewissue.createdsuccess"} />
            </p>
          ) : (
            <p>
              <FormattedMessage id={"createnewissue.createdfail"} />
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton
            type={"button"}
            className={"floatLeft"}
            onClick={() => toggle()}
          >
            <FormattedMessage id={"close"} />
          </SecondaryButton>
          <PrimaryButton
            type={"button"}
            className={"floatRight"}
            onClick={() => navigate("..")}
          >
            <FormattedMessage id={"returntoissuelist"} />
          </PrimaryButton>
        </Modal.Footer>
      </Modal>
      {(loadingSend || loadingPerson) && <LoadingView />}
    </>
  );
};
export default CreateNewIssueView;
