import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useGet } from "@collabodoc/component-library";
import * as signalR from "@microsoft/signalr";
import React, { createContext, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { ISSUE_STATUS, ISSUE_TABLE_TYPE } from "./enums/patientcontactenums";
import { API_URLS } from "./enums/urls";

export const IssueContext = createContext({});
export const IssueContextProvider = () => {
  const { careCenterId } = useParams();
  const { accessToken } = useOidcAccessToken();
  const {
    doGet: fetchIssues,
    response: issues = [],
    isLoading: isLoadingIssues,
  } = useGet(API_URLS.ISSUES(careCenterId), accessToken);
  const [tableType, setTableType] = useState(ISSUE_TABLE_TYPE.ACTIVE);
  const [statusFilters, setStatusFilters] = useState([
    ISSUE_STATUS.WAITING_ANSWER,
    ISSUE_STATUS.ANSWERED,
    ISSUE_STATUS.CHAT_CLOSED,
  ]);
  const [activePage, setActivePage] = useState(1);

  const signalrInit = (careCenterId, reFetchIssues) => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(API_URLS.ISSUE_SIGNALR_HUB())
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Warning)
      .build();
    connection
      .start()
      .then(() => {
        connection
          .invoke("StartListening", careCenterId.toString())
          .catch((err) => console.error(err.toString()));
      })
      .catch((err) => console.error(err.toString()));
    connection.on("refresh", () => {
      reFetchIssues();
    });
  };

  useEffect(() => {
    signalrInit(careCenterId, fetchIssues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careCenterId]);

  return (
    <IssueContext.Provider
      value={{
        issues,
        reFetchIssues: fetchIssues,
        isLoadingIssues,
        tableType,
        setTableType,
        statusFilters,
        setStatusFilters,
        activePage,
        setActivePage,
      }}
    >
      <Outlet />
    </IssueContext.Provider>
  );
};
