import React, {useState } from "react";
import { Card, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { MESSAGE_TYPE } from "../../enums/patientcontactenums";
import ChatContainer from "./ChatContainer";
import ReplyCard from "./ReplyCard";
import VideoReplyCard from "./VideoReplyCard";

const ChatCard = ({ issue, isLoading, reFetchIssue }) => {
    const [replyType, setReplyType] = useState(MESSAGE_TYPE.NORMAL);
    const [toggleChecked, setToggleChecked] = useState(true);

    const handleSwitch = () => {
        if (replyType === MESSAGE_TYPE.NORMAL) {
            setReplyType(MESSAGE_TYPE.VIDEOLINK)
        } else {
            setReplyType(MESSAGE_TYPE.NORMAL)
        }
        setToggleChecked(!toggleChecked);
        document.getElementById("toggle").checked = toggleChecked;
    };

    return (
        <Card>
            <Card.Header>
                <h4 className="dispInline">
                    <FormattedMessage id={"chatcard.header"} />
                </h4>
            </Card.Header>
                <ChatContainer issue={issue} isLoading={isLoading} />
                {!issue.isChatClosed &&
                    <Col xs={12}>
                        <div className="paddingTop floatRight toggleCloser">
                            <strong className="toggleLabelLeft dispInline"><FormattedMessage id={"textmessage"} /></strong>
                            <label className="switch">
                                <input id="toggle" type="checkbox" onChange={handleSwitch} />
                                <span className="slider round" />
                            </label>
                            <strong className="toggleLabelRight dispInline"><FormattedMessage
                                id={"chatcard.videomeetingmessage"} /></strong>
                        </div>
                    </Col>
                }
                {!issue.isChatClosed && replyType === MESSAGE_TYPE.VIDEOLINK ?
                    <VideoReplyCard
                        issue={issue}
                        reFetchIssue={reFetchIssue}
                    /> :
                    <ReplyCard
                        issue={issue}
                        reFetchIssue={reFetchIssue}
                    />
                }

        </Card>
    )
};
export default ChatCard;
