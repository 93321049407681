import React from 'react';
import {Col} from 'react-bootstrap';
import {FormattedMessage} from "react-intl";

const Pagination = ({totalNumberOfRows, rowsPerPage, activePage, onClick}) => {
    const pageNumbers = [];
    let rowsShown;
    let hidePagination = "d-none";

    for (let i = 1; i <= Math.ceil(totalNumberOfRows / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    if (totalNumberOfRows > rowsPerPage) {
        if (activePage === pageNumbers.length) {
            rowsShown = totalNumberOfRows - (rowsPerPage * (pageNumbers.length - 1));
        } else {
            rowsShown = rowsPerPage;
        }
        hidePagination = "";
    } else {
        rowsShown = totalNumberOfRows;
    }

    return (
        <Col className="text-center">
            <p>
                <FormattedMessage id="pagination.nowshowing" values={{rows: rowsShown, totalRows: totalNumberOfRows}}/>
            </p>
            <nav className={hidePagination}>
                <ul className="pagination justify-content-center">
                    {pageNumbers.map(number => (
                        <li key={number}
                            className={number === activePage ? "page-item active" : "page-item"}>
                            <a onClick={() => onClick(number)} className="page-link">
                                {number}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </Col>
    )
}

export default Pagination;