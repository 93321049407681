import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  getRequestInit,
  LoadingView,
  useFetchy,
} from "@collabodoc/component-library";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { StatisticsView } from "../../enums/statisticsenums";
import { API_URLS } from "../../enums/urls";
import Statistics from "./Statistics";

const StatisticsContainer = () => {
  let year = dayjs().year();
  let initialDate = dayjs(`${year}-01-01`);
  const { accessToken } = useOidcAccessToken();
  const [endDate, setEndDate] = useState(dayjs());
  const [startDate, setStartDate] = useState(initialDate);
  const [selectedYear, setSelectedYear] = useState(year);
  const [currentEventKey, setCurrentEventKey] = useState(
    StatisticsView.Issues_1
  );
  const { careCenterId } = useParams();

  const { data: issueStatistics = {}, isLoading: loadingStatistics } =
    useFetchy(
      API_URLS.STATISTICS(careCenterId, startDate, endDate),
      getRequestInit({ accessToken })
    );

  if (loadingStatistics) {
    return <LoadingView />;
  }

  let years = issueStatistics?.years || [];
  if (!issueStatistics?.years?.find((x) => x === year)) {
    years.push(year);
  }
  years.sort((a, b) => (a > b ? 1 : -1));

  return (
    <Statistics
      issueStatistics={issueStatistics}
      initialStartDate={issueStatistics && dayjs(issueStatistics.dataStartDate)}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      currentStartDate={startDate}
      currentEndDate={endDate}
      currentEventKey={currentEventKey}
      setCurrentEventKey={setCurrentEventKey}
      years={issueStatistics && years}
      selectedYear={selectedYear}
      setSelectedYear={setSelectedYear}
    />
  );
};

export default StatisticsContainer;
