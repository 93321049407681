import { PrimaryButton, SecondaryButton } from "@collabodoc/component-library";
import { faArrowsAltH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import DatePicker, { registerLocale } from "react-datepicker";
import { LOCALE } from "../../utils/localeUtils";
import sv from 'date-fns/locale/sv';
import { FormattedMessage } from "react-intl";
import { Periods } from "../../enums/statisticsenums";

registerLocale('sv', sv);

const PeriodSelector = ({
    years,
    selectedYear,
    setStartDate,
    currentEndDate,
    setEndDate,
    currentStartDate,
    initialStartDate,
    setSelectedYear
}) => {

    const initialEndDate = dayjs();
    const [show, setShow] = useState(selectedYear === Periods.Custom);

    const updateStartDate = (date) => {
        setStartDate(dayjs(date));
        if (!currentEndDate) {
            setEndDate(initialEndDate)
        }
    }

    const updateEndDate = (date) => {
        setEndDate(dayjs(date));
        if (!currentStartDate) {
            setStartDate(initialStartDate)
        }
    }

    const handlePeriodChange = (year) => {

        switch (year) {
            case Periods.Custom:
                setSelectedYear(Periods.Custom);
                setShow(true);
                break;
            case Periods.All:
                setSelectedYear(Periods.All);
                setStartDate(null);
                setEndDate(null);
                setShow(false);
                break;
            default:
                setSelectedYear(year);
                setStartDate(`${year}-01-01`);
                setShow(false);
                if (year === dayjs().year()) {
                    setEndDate(dayjs());
                }
                else {
                    setEndDate(`${year}-12-31`);
                }
                break;
        }
    }

    return (
        <>
            <Row>
                <Col>
                    {selectedYear === Periods.Custom ?
                        <SelectedYearButton onClick={() => handlePeriodChange(Periods.Custom)}><FormattedMessage id='custom' /></SelectedYearButton>
                        : <YearButton onClick={() => handlePeriodChange(Periods.Custom)}><FormattedMessage id='custom' /></YearButton>}
                    {selectedYear === Periods.All ?
                        <SelectedYearButton onClick={() => handlePeriodChange(Periods.All)}><FormattedMessage id='all' /></SelectedYearButton>
                        : <YearButton onClick={() => handlePeriodChange(Periods.All)}><FormattedMessage id='all' /></YearButton>}
                    {years.map(year => {
                        if (year === selectedYear) {
                            return <SelectedYearButton key={year} onClick={() => handlePeriodChange(year)}>{year}</SelectedYearButton>
                        }
                        return <YearButton key={year} onClick={() => handlePeriodChange(year)}>{year}</YearButton>
                    })}
                </Col>
            </Row>
            {show &&
                <DateDiv>
                    <DatePicker
                        selected={currentStartDate ? new Date(currentStartDate) : new Date(initialStartDate)}
                        minDate={initialStartDate}
                        maxDate={initialEndDate}
                        onChange={date => updateStartDate(date)}
                        locale={LOCALE}
                        dateFormat={"dd MMMM yyyy"}
                        shouldCloseOnSelect={true}
                    />
                    <Icon icon={faArrowsAltH} />
                    <DatePicker
                        selected={currentEndDate ? new Date(currentEndDate) : new Date(initialEndDate)}
                        minDate={initialStartDate}
                        maxDate={initialEndDate}
                        onChange={date => updateEndDate(date)}
                        locale={LOCALE}
                        dateFormat={"dd MMMM yyyy"}
                        shouldCloseOnSelect={true}
                    />
                </DateDiv>
            }
        </>
    );
};

export default PeriodSelector;

const YearButton = styled(SecondaryButton)`
float: right;
font-size: 12px;
padding: 1px 6px;
margin-right: 5px;
margin-bottom:5px;
`;

const SelectedYearButton = styled(PrimaryButton)`
float: right;
font-size: 12px;
padding: 1px 6px;
margin-right: 5px;
margin-bottom:5px;
`

const Icon = styled(FontAwesomeIcon)`
margin-top: 6px;
margin-left: 3px;
margin-right: 3px;

`;

const DateDiv = styled.div`
float:right;
display: flex;
padding-bottom: 5px;
`;