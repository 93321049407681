import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  H2,
  PrimaryButton,
  SecondaryButton,
  TextAreaWithLabelAndError,
  usePut,
} from "@collabodoc/component-library";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { API_URLS } from "../../enums/urls";

const CommentModal = ({
  issue,
  toggleCommentModal,
  showCommentModal,
  hideCommentModal,
}) => {
  const { careCenterId } = useParams();
  const { accessToken } = useOidcAccessToken();
  const [comment, setComment] = useState(issue?.comment || "");
  const { doPut: updateComment } = usePut(
    API_URLS.ISSUE_COMMENT(careCenterId, issue.issueId),
    accessToken
  );

  return (
    <Modal show={showCommentModal} onHide={hideCommentModal} autoFocus={false}>
      <Modal.Body>
        <TextAreaWithLabelAndError
          label={"Kommentar"}
          autoFocus={true}
          value={comment}
          className="form-control"
          minRows={5}
          maxRows={10}
          id={"commentModal"}
          handleChange={(text) => setComment(text)}
        />
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          type={"button"}
          className={"floatRight"}
          onClick={() =>
            updateComment({ commentText: comment }).then(() =>
              toggleCommentModal()
            )
          }
        >
          <FormattedMessage id="save" />
        </PrimaryButton>
        <SecondaryButton
          type={"button"}
          className={"floatRight"}
          onClick={() => {
            setComment(issue?.comment || "");
            toggleCommentModal();
          }}
        >
          <FormattedMessage id="abort" />
        </SecondaryButton>
      </Modal.Footer>
    </Modal>
  );
};
export default CommentModal;
