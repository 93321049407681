import React from "react";
import { Container } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Outlet } from "react-router-dom";
import NavMenu from "./components/NavMenu";
import { setMomentLocale } from "./utils/dateUtils";
import { GlobalContextProvider } from "./views/GlobalContext";

const Root = () => {
  const intl = useIntl();
  setMomentLocale(intl);
  return (
    <GlobalContextProvider>
      <NavMenu />
      <Container fluid>
        <Outlet />
      </Container>
    </GlobalContextProvider>
  );
};

export default Root;
