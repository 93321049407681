import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  LoadingView,
  PrimaryButton,
  usePut,
} from "@collabodoc/component-library";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sv from "date-fns/locale/sv";
import properNameCase from "proper-name-case";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import "../../Content/Style/datepicker.css";
import { API_URLS } from "../../enums/urls";
import { getCalendarDateFormat } from "../../utils/dateUtils";
import { LOCALE } from "../../utils/localeUtils";
import { GlobalContext } from "../GlobalContext";
import UserSelector from "./UserSelector";

registerLocale("sv", sv);

//unused ref needed because https://github.com/Hacker0x01/react-datepicker/issues/862
const TimeAndDataField = forwardRef(({ value, onClick }, refs) => {
  const intl = useIntl();
  return (
    <span onClick={onClick}>
      <FontAwesomeIcon icon={faCalendarAlt} />{" "}
      {value || intl.formatMessage({ id: "videoreplycard.settimeanddate" })}
    </span>
  );
});

const VideoReplyCard = ({ issue, reFetchIssue }) => {
  const intl = useIntl();
  const [reply, setReply] = useState({});
  const [timeAndDate, setTimeAndDate] = useState(null);
  const { careCenterId } = useParams();
  const { oidcUser } = useContext(GlobalContext);
  const { accessToken } = useOidcAccessToken();

  const { doPut: sendVideoLinkMessage } = usePut(
    API_URLS.ISSUE_MESSAGE_VIDEOLINK(careCenterId, issue && issue.issueId),
    accessToken
  );

  const { doPut: closeChat, isLoading: isLoadingCloseChat } = usePut(
    API_URLS.ISSUE_CLOSE_CHAT(careCenterId, issue && issue.issueId),
    accessToken
  );
  const userFirstName = properNameCase.convert(oidcUser.firstname);
  const userLastName = properNameCase.convert(oidcUser.lastname);
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  const handleCloseChat = () => closeChat().then(() => reFetchIssue());

  const sendVideoUrlMessage = () => {
    sendVideoLinkMessage({
      message: reply.messageText,
      meetingTime: timeAndDate,
    }).then(
      () => reFetchIssue(),
      setSelectedDoctor(null),
      setTimeAndDate(null)
    );
  };

  useEffect(() => {
    if (!timeAndDate) {
      setReply({
        messageText: `${intl.formatMessage({
          id: "videoreplycard.regardingissue",
        })} ${issue.issueOwner && issue.issueOwner.firstName} ${
          issue.issueOwner && issue.issueOwner.lastName
        }.\n${selectedDoctor && selectedDoctor}, ${intl.formatMessage({
          id: "videoreplycard.shouldhavevideomeeting",
        })} \n${intl.formatMessage({
          id: "videoreplycard.linktovideomeeting",
        })}.`,
      });
    } else {
      setReply({
        messageText: `${intl.formatMessage({
          id: "videoreplycard.regardingissue",
        })} ${issue.issueOwner && issue.issueOwner.firstName} ${
          issue.issueOwner && issue.issueOwner.lastName
        }.\n${selectedDoctor && selectedDoctor}, ${intl.formatMessage({
          id: "videoreplycard.shouldhavevideomeeting",
        })} \n${intl.formatMessage({
          id: "time",
        })}: ${timeAndDate.toLocaleString("sv-SE", {
          dateStyle: "medium",
          timeStyle: "short",
        })} \n${intl.formatMessage({
          id: "videoreplycard.timeisnotok",
        })} \n${intl.formatMessage({
          id: "videoreplycard.linktovideomeeting",
        })}${intl.formatMessage({
          id: "videoreplycard.beforemeeting",
        })}`,
      });
    }
  }, [
    setReply,
    issue,
    userFirstName,
    userLastName,
    timeAndDate,
    selectedDoctor,
    intl,
  ]);

  const handleChange = (selectedDoctor) => {
    setSelectedDoctor(selectedDoctor);
  };

  const handleDateTimeChange = (dateTime) => {
    setTimeAndDate(dateTime);
  };

  if (isLoadingCloseChat) return <LoadingView />;

  return (
    <Card.Body>
      <Row>
        <Col xs={12}>
          <strong>
            <FormattedMessage id={"medicalstaff"} />
          </strong>
          <UserSelector selectDoctor={handleChange} />
        </Col>
      </Row>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <Col xs={12}>
          <strong>
            <FormattedMessage id={"videoreplycard.timeforvideomeeting"} />
          </strong>
          <br />
          <DatePicker
            selected={timeAndDate}
            showTimeSelect
            customInput={<TimeAndDataField />}
            timeFormat={"HH:mm"}
            minDate={new Date()}
            onChange={(date) => handleDateTimeChange(date)}
            timeIntervals={5}
            locale={LOCALE}
            timeCaption={intl.formatMessage({ id: "time" })}
            dateFormat={intl.formatMessage(
              { id: "videoreplycard.timeanddate" },
              {
                timeFormat: "HH:mm",
                dateFormat: "dd MMMM yyyy",
              }
            )}
            placeholderText={intl.formatMessage({
              id: "videoreplycard.clicktoselectime",
            })}
            isClearable={true}
            shouldCloseOnSelect={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        {!issue.isChatClosed && selectedDoctor && (
          <Col s={12} xs={12}>
            <strong>
              <FormattedMessage id={"videoreplycard.messagetopatient"} />
            </strong>
            <div className="well preWrapText videolinkmessage">
              {reply.messageText}
            </div>
          </Col>
        )}
      </Row>
      <Card.Footer className={"text-right"}>
        {issue.isChatClosed && (
          <div className={"text-left"}>
            <FontAwesomeIcon icon={"check"} size="sm" />
            {` ${intl.formatMessage({
              id: "videoreplycard.dialogueclosed",
            })} ${getCalendarDateFormat(issue.timeOfChatClosed).toLowerCase()}`}
          </div>
        )}
        {!issue.isClosed && !issue.isChatClosed && (
          <>
            <PrimaryButton
              type={"button"}
              disabled={!selectedDoctor || !timeAndDate}
              onClick={sendVideoUrlMessage}
            >
              <FontAwesomeIcon icon={"video"} size="sm" />
              &nbsp;
              <FormattedMessage id={"send"} />
            </PrimaryButton>
          </>
        )}
        <PrimaryButton
          type={"button"}
          className="btn-closechat"
          disabled={!reply.messageText}
          onClick={() => handleCloseChat()}
        >
          <FormattedMessage id={"closedialogue"} />
        </PrimaryButton>
      </Card.Footer>
    </Card.Body>
  );
};
export default VideoReplyCard;
