import React from "react";
import {Col, Row} from "react-bootstrap";
import {getCalendarDateFormat} from "../../utils/dateUtils";
import {AttachmentsList} from "./AttachmentsList";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ChatMessage = ({message, currentSender, currentReceiverName, issueId, timeOfRead}) => {

  const datetime = getCalendarDateFormat(message.timeOfCreated);
  const messageRead = getCalendarDateFormat(timeOfRead);
  
  return (
      message.senderEnum === currentSender ?
          <Row>
            <Col xs={{span: 10, offset: 2}} md={{span: 8, offset: 4}} className="floatRight chatMessage smallText">
              <strong>{message.senderName},</strong> <i>{datetime}</i>
              <div className="currentSender">
                {message.messageText}
                <AttachmentsList issueId={issueId} attachments={message.attachments}/>
              </div>
              <div className="floatRight paddingRight10 smallText">
                {messageRead !== "Invalid date" &&
                      <i>Läst: {messageRead} <FontAwesomeIcon icon={faCheck}/></i>}
              </div>
            </Col>
          </Row>
          :
          <Row>
            <Col xs={10} md={8} className="floatLeft chatMessage smallText">
              <div><strong>{currentReceiverName},</strong> <i>{datetime}</i></div>
              <div className="currentReceiver">
                {message.messageText}
                <AttachmentsList issueId={issueId} attachments={message.attachments}/>
              </div>
            </Col>
          </Row>
  );
}
export default ChatMessage;
