import { P } from "@collabodoc/component-library";
import { ResponsiveSwarmPlotCanvas } from "@nivo/swarmplot";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { StatisticsTheme } from "../../enums/statisticsenums";
import { NivoContainer } from "./NivoContainer";

dayjs.extend(duration);

const IssueStatisticsTwo = ({ issueStatistics: { issues = [] } }) => {
  let distinctMonths = [];
  var monthsWithIssues = issues.map((issue) => issue.month);
  for (const month of monthsWithIssues) {
    if (!distinctMonths.includes(month)) {
      distinctMonths.push(month);
    }
  }

  const groupedIssuesPerMonth = [];
  monthsWithIssues.forEach((month) => {
    if (
      !groupedIssuesPerMonth.map((perMonth) => perMonth.month).includes(month)
    ) {
      groupedIssuesPerMonth.push({ month: month, value: 1 });
    } else {
      var countedMonth = groupedIssuesPerMonth.find(
        (perMonth) => perMonth.month === month
      );
      countedMonth.value = countedMonth.value + 1;
    }
  });

  const getToolTip = (node) => {
    const lifeTime =
      dayjs(node.data.issueEndDate) - dayjs(node.data.issueStartDate);

    return (
      <div style={{ backgroundColor: "#ffe150", padding: "2px" }}>
        <span>Livslängd dagar: {dayjs.duration(lifeTime).days()}</span>
        <br />
        <span>Livslängd timmar: {dayjs.duration(lifeTime).hours()}</span>
        <br />
        <span>Antal meddelanden: {node.data.numberOfMessages}</span>
      </div>
    );
  };

  let currentYear = dayjs().year();
  let data = issues.filter(
    (issue) =>
      issue.issueEndDate !== null &&
      dayjs(issue.issueStartDate).year() === currentYear
  );

  return (
    <>
      <UpperDiv>
        <Row>
          <Col xs={12} sm={12}>
            <NivoContainer height={"700px"} left right>
              <h5 className="statisticsLabel">
                <FormattedMessage
                  id="issuelifetime"
                  values={{ currentYear: currentYear }}
                />
              </h5>
              {distinctMonths.length > 1 && (
                <ResponsiveSwarmPlotCanvas
                  data={data}
                  groups={distinctMonths}
                  groupBy="month"
                  value={({ issueStartDate, issueEndDate }) =>
                    dayjs
                      .duration(dayjs(issueEndDate) - dayjs(issueStartDate))
                      .days()
                  }
                  valueFormat=".0f"
                  size={{
                    key: "numberOfMessages",
                    values: [5, 25],
                    sizes: [10, 45],
                  }}
                  colors={StatisticsTheme}
                  margin={{ top: 80, right: 100, bottom: 80, left: 100 }}
                  axisTop={false}
                  tooltip={(node) => getToolTip(node)}
                  axisLeft={{
                    legend: "Livslängd dagar",
                    legendPosition: "middle",
                    legendOffset: -60,
                  }}
                  axisBottom={{
                    tickPadding: 20,
                  }}
                />
              )}
            </NivoContainer>
          </Col>
        </Row>
      </UpperDiv>
      <InfoTextSection>
        <Row>
          <Col>
            <StatisticsInfoText>
              <FormattedMessage id="swarmplotinfotext" />
            </StatisticsInfoText>
          </Col>
        </Row>
      </InfoTextSection>
    </>
  );
};

export default IssueStatisticsTwo;

const StatisticsInfoText = styled(P)`
  padding-left: 20px;
  font-style: italic;
`;

const InfoTextSection = styled.div`
  margin-top: 700px;
`;

const UpperDiv = styled.div`
  margin-top: 20px;
`;
