const ISSUE_STATUS = {
    RECEIVED: 1,
    WAITING_ANSWER: 2,
    ANSWERED: 3,
    CHAT_CLOSED: 4,
    CLOSED: 5
};

const SENDER = {
    PATIENT: 0,
    CAREGIVER: 1
};

const MESSAGE_TYPE = {
    NORMAL: 0,
    VIDEOLINK: 1
};

const CARE_CENTER_SETTING = {
    VIDEO_URL: 12
};

const ISSUE_TABLE_TYPE = {
    ACTIVE: 0,
    CLOSED: 1
}

const ADMIN_ROLES = {
    Admin: "Admin"
}

const CLINIC_ROLES = {
    DOCTOR: "DoctorUser",
    NURSE: "NurseUser",
    SECRETARY: "SecretaryUser",
    PHYSIOTHERAPIST: "PhysiotherapistUser",
    PSYCHOLOGIST: "PsychologistUser",
    COUNSELOR: "CounselorUser",
    OCCUPATIONAL_THERAPIST: "OccupationalTherapistUser"
}

const ANONYMIZATIONCONSTANTS = {
    NumberOfDaysBeforeAnonymization: 7
}


export {ISSUE_STATUS, SENDER, ISSUE_TABLE_TYPE, MESSAGE_TYPE, CARE_CENTER_SETTING, ADMIN_ROLES, CLINIC_ROLES, ANONYMIZATIONCONSTANTS};
