import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { DropDown, useGet } from "@collabodoc/component-library";
import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { API_URLS } from "../../enums/urls";
import { useRoles } from "../../utils/roleUtils";

const UserSelector = ({ selectDoctor }) => {
  const { careCenterId } = useParams();
  const { accessToken } = useOidcAccessToken();
  const { response: users = [] } = useGet(
    API_URLS.USERS(careCenterId),
    accessToken
  );
  const intl = useIntl();
  const { getClinicRoleName, hasClinicRole } = useRoles();

  const options =
    users &&
    users
      .filter((user) => hasClinicRole(user.roles))
      .map((user) => {
        const userclinicrole = getClinicRoleName(user.roles);
        return {
          id: user.userId,
          text: `${user.firstName} ${user.lastName}${
            userclinicrole && ", " + userclinicrole
          }`,
        };
      });

  return (
    <Wrap>
      <DropDown
        placeholder={intl.formatMessage({ id: "userselector.choosepersonel" })}
        options={options}
        handler={(id, text) => selectDoctor(text)}
      ></DropDown>
    </Wrap>
  );
};
export default UserSelector;

const Wrap = styled.div`
  > div {
    > div:nth-child(2) {
      max-height: 180px;
      overflow-y: auto;
    }
  }
`;
