import { useIntl } from "react-intl";
import { CLINIC_ROLES } from "../enums/patientcontactenums";
import DoctorIcon from "../Content/Images/Icons/Doctor.svg"
import NurseIcon from "../Content/Images/Icons/Nurse.svg"
import PhysiotherapistIcon from "../Content/Images/Icons/Physiotherapist.svg"
import SecretaryIcon from "../Content/Images/Icons/Secretary.svg"
import PsychologistIcon from "../Content/Images/Icons/Psychologist.svg"
import OccupationalTherapistIcon from "../Content/Images/Icons/OccupationalTherapist.svg";
import CounselorIcon from "../Content/Images/Icons/Counselor.svg";


const useRoles = () => {
    const intl = useIntl();
    const translations = {
        [CLINIC_ROLES.DOCTOR]: intl.formatMessage({ id: "roles.clinical.doctor" }),
        [CLINIC_ROLES.NURSE]: intl.formatMessage({ id: "roles.clinical.nurse" }),
        [CLINIC_ROLES.SECRETARY]: intl.formatMessage({ id: "roles.clinical.secretary" }),
        [CLINIC_ROLES.PHYSIOTHERAPIST]: intl.formatMessage({ id: "roles.clinical.physiotherapist" }),
        [CLINIC_ROLES.PSYCHOLOGIST]: intl.formatMessage({id: "roles.clinical.psychologist"}),
        [CLINIC_ROLES.COUNSELOR]: intl.formatMessage({id: "roles.clinical.counselor"}),
        [CLINIC_ROLES.OCCUPATIONAL_THERAPIST]: intl.formatMessage({id: "roles.clinical.occupational-therapist"}),
    };

    const getClinicRoleName = (role) => {
        let clinicalRole;
        if (Array.isArray(role)) {
            clinicalRole = role.find(r => Object.values(CLINIC_ROLES).includes(r));
        }
        else {
            clinicalRole = Object.values(CLINIC_ROLES).includes(role) && role;
        }
        return clinicalRole ? translations[clinicalRole] : "";
    }

    const hasClinicRole = (userRoles) => {
        const clinicRoles = Object.values(CLINIC_ROLES);
        if (Array.isArray(userRoles)) {
            return userRoles.some(r => clinicRoles.includes(r));
        }
        return clinicRoles.includes(userRoles);
    }

    return { getClinicRoleName, hasClinicRole };
}

const getRoleIcon = (role) => {
    switch (role) {
        case CLINIC_ROLES.NURSE:
            return NurseIcon;
        case CLINIC_ROLES.DOCTOR:
            return DoctorIcon;
        case CLINIC_ROLES.PHYSIOTHERAPIST:
            return PhysiotherapistIcon;
        case CLINIC_ROLES.SECRETARY:
            return SecretaryIcon;
        case CLINIC_ROLES.PSYCHOLOGIST:
            return PsychologistIcon;
        case CLINIC_ROLES.OCCUPATIONAL_THERAPIST:
            return OccupationalTherapistIcon;
        case CLINIC_ROLES.COUNSELOR:
            return CounselorIcon;
        default:
            return "";
    }
}

export { useRoles, getRoleIcon };
