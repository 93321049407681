import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { usePut } from "@collabodoc/component-library";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import useOnclickOutside from "react-cool-onclickoutside";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { ISSUE_TABLE_TYPE } from "../../enums/patientcontactenums";
import { API_URLS } from "../../enums/urls";
import { DeleteModal } from "./DeleteModal";

export const DropDownMenu = ({
  issue,
  tableType,
  showMenu,
  toggleMenu,
  setOpen,
  handleRowClick,
}) => {
  const { accessToken } = useOidcAccessToken();
  const { careCenterId } = useParams();
  const { doPut: handleReactivate } = usePut(
    API_URLS.ISSUE_REACTIVATE(careCenterId, issue.issueId),
    accessToken
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleClickOutside = useOnclickOutside(() => {
    setOpen(false);
  });

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  return (
    <>
      <DeleteModal
        issue={issue}
        showDeleteModal={showDeleteModal}
        toggleDeleteModal={toggleDeleteModal}
        hideDeleteModal={() => setShowDeleteModal(false)}
      />

      <Dropdown show={showMenu} onClick={toggleMenu} className="dropdown">
        <Dropdown.Toggle className="fa fa-ellipsis-h manage-btn" />
        <Dropdown.Menu ref={handleClickOutside}>
          <Dropdown.Item onClick={handleRowClick}>
            <FormattedMessage id="issuetablerow.dropdown.open" />
          </Dropdown.Item>
          {tableType === ISSUE_TABLE_TYPE.CLOSED && (
            <Dropdown.Item
              data-issueid={issue.issueId}
              onClick={() => handleReactivate()}
            >
              <FormattedMessage id="issuetablerow.dropdown.movetoongoing" />
            </Dropdown.Item>
          )}
          <Dropdown.Item
            data-issueid={issue.issueId}
            onClick={toggleDeleteModal}
          >
            <FormattedMessage id="delete" />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
