import { useOidc } from "@axa-fr/react-oidc";
import styled from "styled-components";
import { PrimaryButton } from "@collabodoc/component-library";

export const CustomSessionLost = ({ configurationName }) => {
  const { login } = useOidc(configurationName);
  return (
    <SessionLost>
      <SessionLostContainer>
        <h4>Din session har gått ut och du har blivit utloggad!</h4>
        <PrimaryButton type={"button"} onClick={() => login(null)}>
          Logga in
        </PrimaryButton>
      </SessionLostContainer>
    </SessionLost>
  );
};
const SessionLost = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;
const SessionLostContainer = styled.div`
  text-align: center;
`;
