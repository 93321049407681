import {
  PrimaryButton,
  SearchBarWithLabel,
  SecondaryButton,
} from "@collabodoc/component-library";
import { faInfoCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Pagination from "../../components/Pagination";
import {
  ANONYMIZATIONCONSTANTS,
  ISSUE_TABLE_TYPE,
} from "../../enums/patientcontactenums";
import { VIEWS } from "../../enums/urls";
import { IssueContext } from "../../IssueContext";
import IssueTable from "./IssueTable";

const ROWS_PER_PAGINATED_PAGE = 15;
const sortIssues = (issuesToSort) => {
  const getTimeOfFirstMessage = (issue) =>
    new Date(issue.messages[0].timeOfCreated);
  return issuesToSort.sort(
    (a, b) => getTimeOfFirstMessage(b) - getTimeOfFirstMessage(a)
  );
};
const filterByTableType = (issues, tableType) => {
  switch (tableType) {
    case ISSUE_TABLE_TYPE.ACTIVE:
      return issues.filter((issue) => !issue.isClosed);
    case ISSUE_TABLE_TYPE.CLOSED:
      return issues.filter((issue) => issue.isClosed);
    default:
      return issues;
  }
};
const filterByStatus = (issues, statusFilters) =>
  issues.filter((issue) => statusFilters.includes(issue.issueStatus));
const filterBySearchText = (issues = [], searchText = "") => {
  if (!searchText) return issues;
  const keys = ["firstName", "lastName", "personalNumber"];
  const words = searchText.toLowerCase().split(" ");
  return issues.filter((issue) =>
    words.every((word) =>
      keys.some((key) => issue.issueOwner[key].toLowerCase().includes(word))
    )
  );
};
const filterByPage = (issues, activePage) => {
  // Get current rows
  const indexOfLastRow = activePage * ROWS_PER_PAGINATED_PAGE;
  const indexOfFirstRow = indexOfLastRow - ROWS_PER_PAGINATED_PAGE;
  return issues.slice(indexOfFirstRow, indexOfLastRow);
};
const IssueListView = () => {
  const {
    issues,
    tableType,
    setTableType,
    statusFilters,
    activePage,
    setActivePage,
  } = useContext(IssueContext);

  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const handleTableTypeChange = (newTableType) => {
    if (newTableType !== tableType) {
      setSearchText("");
      setActivePage(1);
      setTableType(newTableType);
    }
  };

  let filteredIssues = filterByTableType(issues, tableType);
  if (tableType !== ISSUE_TABLE_TYPE.CLOSED) {
    filteredIssues = filterByStatus(filteredIssues, statusFilters);
  }
  filteredIssues = filterBySearchText(filteredIssues, searchText);
  filteredIssues = sortIssues(filteredIssues);
  const issuesOnPage = filterByPage(filteredIssues, activePage);
  const intl = useIntl();
  return (
    <>
      <div>
        <Row>
          <Col className={"topMargin-sm"} xs={5} md={4} lg={5}>
            {tableType === ISSUE_TABLE_TYPE.ACTIVE ? (
              <>
                <PrimaryButton
                  type={"button"}
                  className={"rightMargin-sm"}
                  onClick={() => handleTableTypeChange(ISSUE_TABLE_TYPE.ACTIVE)}
                >
                  <FormattedMessage id="issuelist.button.ongoing" />
                </PrimaryButton>
                <SecondaryButton
                  type={"button"}
                  onClick={() => handleTableTypeChange(ISSUE_TABLE_TYPE.CLOSED)}
                >
                  <FormattedMessage id="issuelist.button.closed" />
                </SecondaryButton>
              </>
            ) : (
              <>
                <SecondaryButton
                  type={"button"}
                  className={"rightMargin-sm"}
                  onClick={() => handleTableTypeChange(ISSUE_TABLE_TYPE.ACTIVE)}
                >
                  <FormattedMessage id="issuelist.button.ongoing" />
                </SecondaryButton>
                <PrimaryButton
                  type={"button"}
                  onClick={() => handleTableTypeChange(ISSUE_TABLE_TYPE.CLOSED)}
                >
                  <FormattedMessage id="issuelist.button.closed" />
                </PrimaryButton>
              </>
            )}
          </Col>
          <Col className={"topMargin-sm"} xs={4} md={5} lg={3}>
            <span className="floatRight">
              <SecondaryButton
                type={"button"}
                onClick={() => navigate(VIEWS.CREATE_NEW_ISSUE)}
              >
                <FormattedMessage id="issuelist.button.createissue" />{" "}
                <FontAwesomeIcon icon={faPlus} />
              </SecondaryButton>
              &nbsp;
            </span>
          </Col>
          <Col className={"topMargin-sm"} xs={3} lg={4}>
            <SearchBarWithLabel
              handleChange={setSearchText}
              value={searchText}
              placeholder={intl.formatMessage({ id: "search" })}
            />
          </Col>
        </Row>
        <br />
        <IssueTable issues={issuesOnPage} tableType={tableType} />
        <Pagination
          rowsPerPage={ROWS_PER_PAGINATED_PAGE}
          totalNumberOfRows={filteredIssues.length}
          activePage={activePage}
          onClick={setActivePage}
        />
      </div>
      <InfoFooter>
        <FontAwesomeIcon className="" icon={faInfoCircle} />
        &nbsp; Ärenden tas bort{" "}
        {ANONYMIZATIONCONSTANTS.NumberOfDaysBeforeAnonymization} dagar efter
        senaste aktivitet i chatten eller{" "}
        {ANONYMIZATIONCONSTANTS.NumberOfDaysBeforeAnonymization} dagar efter
        bokad tid för videomöte.
      </InfoFooter>
    </>
  );
};

const InfoFooter = styled.div`
  position: fixed;
  bottom: 0;
  z-index: -1;
  opacity: 0.6;
`;

export default IssueListView;
