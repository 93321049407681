import React from 'react';

const NivoContainer = ({ children, height, left, right }) => {

    let absoluteDivStyle = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        paddingLeft: left ? '20px' : '-20px',
        paddingRight: right ? '20px' : '-20px',
    }

    return (

        <div style={{ position: 'relative' }}>
            <div style={absoluteDivStyle}>
                <div style={{ height: height || '800px', backgroundColor: "white" }}>{children}</div>
            </div>
        </div>
    );
};

export { NivoContainer };