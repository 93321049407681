import { DropDown, H4 } from "@collabodoc/component-library";
import { reverse } from "named-urls";
import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Navigate } from "react-router-dom";
import { VIEWS } from "../enums/urls";
import { GlobalContext } from "./GlobalContext";

const ChooseCareCenterView = () => {
  const { careCenters = [] } = useContext(GlobalContext);
  const defaultCareCenterId =
    careCenters.length === 1 ? careCenters[0].id : null;
  const [selectedCareCenterId, setSelectedCareCenterId] =
    useState(defaultCareCenterId);

  if (selectedCareCenterId) {
    return (
      <Navigate
        to={reverse(VIEWS.CARECENTER, { careCenterId: selectedCareCenterId })}
      />
    );
  }

  return (
    <Container>
      <Row>
        <Col md={{ span: 6, offset: 3 }} className="chooseCareCenter">
          <H4>
            <FormattedMessage id="choosecarecenter.header" />
          </H4>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <DropDown
            placeholder={<FormattedMessage id="choosecarecenter.header" />}
            options={careCenters.map((x) => ({ text: x.name, id: x.id }))}
            handler={(id) => setSelectedCareCenterId(id)}
          ></DropDown>
        </Col>
      </Row>
    </Container>
  );
};
export default ChooseCareCenterView;
