import React from "react";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import AllowPatientFileUpload from "./AllowPatientFileUpload";

const IssueSettingsCard = ({ issue }) => {
    return (
        <Card>
            <Card.Header><h4 className="dispInline"><FormattedMessage id={"issuesettingscard.header"}/></h4></Card.Header>
            <Card.Body className={"tut-enable-attachments"}>
                <AllowPatientFileUpload issue={issue}/>
            </Card.Body>
        </Card>
    );
}
export default IssueSettingsCard;
