import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePieCanvas } from "@nivo/pie";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  StatisticsTheme,
  WeekdayOrder,
  Weekdays,
} from "../../enums/statisticsenums";
import { NivoContainer } from "./NivoContainer";

const IssueStatisticsOne = ({
  issueStatistics: {
    contactReasons = [],
    averageTimeToFirstAnswerPerWeekday = [],
    issues = [],
    numberOfIssues = 0,
  },
}) => {
  const contactReasonData = contactReasons.map(({ key, value }) => {
    return {
      id: key,
      value: value,
    };
  });

  const timeToFirstAnswerData = averageTimeToFirstAnswerPerWeekday.map(
    ({ key, value }) => {
      const valueInHours = value / 3600;
      return { weekday: Weekdays[key], value: valueInHours.toFixed(2) };
    }
  );

  const existingWeekdays = averageTimeToFirstAnswerPerWeekday.map(
    (avg) => avg.key
  );
  for (const wd of Object.keys(Weekdays)) {
    if (!existingWeekdays.includes(wd)) {
      timeToFirstAnswerData.push({ weekday: Weekdays[wd], value: null });
    }
  }

  timeToFirstAnswerData.sort((a, b) => {
    let day1 = a;
    let day2 = b;
    return WeekdayOrder[day1.weekday] - WeekdayOrder[day2.weekday];
  });

  const sortedIssues = issues.sort((a, b) =>
    a.issueStartDate > b.issueStartDate ? 1 : -1
  );

  let distinctMonths = [];
  var monthsWithIssues = sortedIssues.map((issue) => issue.month);
  for (const month of monthsWithIssues) {
    if (!distinctMonths.includes(month)) {
      distinctMonths.push(month);
    }
  }

  const groupedIssuesPerMonth = [];
  monthsWithIssues.forEach((month) => {
    if (
      !groupedIssuesPerMonth.map((perMonth) => perMonth.month).includes(month)
    ) {
      groupedIssuesPerMonth.push({ month: month, value: 1 });
    } else {
      var countedMonth = groupedIssuesPerMonth.find(
        (perMonth) => perMonth.month === month
      );
      countedMonth.value = countedMonth.value + 1;
    }
  });

  const getRadialLabel = (e) => {
    const splitted = e.id.split(" ");
    if (e.id.includes("/")) {
      const arr = e.id.split("/");
      return (
        <>
          <tspan x="0" dy="-0.6em">
            {arr[0]}/
          </tspan>
          <tspan x="0" dy="1.2em">
            {arr[1]}
          </tspan>
        </>
      );
    } else if (splitted.length > 2) {
      const first = splitted.slice(0, 2).join(" ");
      const second = splitted.slice(2).join(" ");
      return (
        <>
          <tspan x="0" dy="-0.6em">
            {first}
          </tspan>
          <tspan x="0" dy="1.2em">
            {second}
          </tspan>
        </>
      );
    }
    return (
      <>
        <tspan>{e.id}</tspan>
      </>
    );
  };

  return (
    <>
      <UpperDiv>
        <Row>
          <Col xs={12} sm={6}>
            <NivoContainer height={"730px"} left>
              <h5 className="statisticsLabel">
                <FormattedMessage id="issuespercontactreason" />
              </h5>
              <ResponsivePieCanvas
                innerRadius={0.5}
                padAngle={0.5}
                colors={StatisticsTheme}
                cornerRadius={5}
                radialLabelsLinkColor={{
                  from: "color",
                }}
                radialLabelsLinkStrokeWidth={5}
                radialLabelsTextXOffset={3}
                radialLabel={(e) => getRadialLabel(e)}
                data={contactReasonData}
                margin={{ top: 65, right: 100, bottom: 50, left: 120 }}
                arcLabelsTextColor="white"
              />
            </NivoContainer>
          </Col>
          <Col xs={12} sm={6}>
            <NivoContainer height={"350px"} right>
              <h5 className="statisticsLabel">
                <FormattedMessage id="numberofissuespermonth" />
              </h5>
              <h5 className="statisticsLabelRight">
                <FormattedMessage
                  id="numberofissues"
                  values={{
                    number: numberOfIssues,
                  }}
                />
              </h5>
              <ResponsiveBar
                data={groupedIssuesPerMonth}
                indexBy="month"
                margin={{ top: 50, right: 30, bottom: 50, left: 110 }}
                padding={0.3}
                valueScale={{ type: "linear" }}
                colors="#ffe150"
                colorBy
                labelTextColor="black"
                enableGridY={true}
                enableGridX={false}
                tooltip={({ indexValue, value }) => (
                  <span>
                    {indexValue}: <b>{value}</b>
                  </span>
                )}
                axisLeft={{
                  legend: "Antal ärenden per månad",
                  legendPosition: "middle",
                  legendOffset: -60,
                }}
              />
            </NivoContainer>
          </Col>
        </Row>
      </UpperDiv>
      <LowerDiv>
        <Row>
          <Col xs={12} sm={6}></Col>
          <Col xs={12} sm={6}>
            <NivoContainer height={"350px"} right>
              <h5 className="statisticsLabel">
                <FormattedMessage id="avgtimetofirstanswer" />
              </h5>
              <ResponsiveBar
                data={timeToFirstAnswerData}
                indexBy="weekday"
                margin={{ top: 50, right: 30, bottom: 50, left: 110 }}
                padding={0.3}
                valueScale={{ type: "linear" }}
                colors="#ffe150"
                colorBy
                labelTextColor="black"
                enableGridY={true}
                enableGridX={false}
                tooltip={({ indexValue, value }) => (
                  <span>
                    {indexValue}: <b>{value}</b>
                  </span>
                )}
                axisLeft={{
                  legend: "Svarstid timmar",
                  legendPosition: "middle",
                  legendOffset: -60,
                }}
              />
            </NivoContainer>
          </Col>
        </Row>
      </LowerDiv>
    </>
  );
};

export default IssueStatisticsOne;

const LowerDiv = styled.div`
  margin-top: 380px;
`;

const UpperDiv = styled.div`
  margin-top: 30px;
`;
