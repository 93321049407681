import sv from "../Content/translations/sv.json";
import nb from "../Content/translations/nb.json";

const messages = {
  sv,
  nb,
};

const LOCALE = String(window.env.REACT_APP_LOCALE);
const getTranslations = () => messages[LOCALE];

export { LOCALE, getTranslations };
