const oidcConfiguration = {
  authority: window.env.authority,
  client_id: window.env.clientId,
  monitor_session: true,
  redirect_uri: `${window.location.origin}/auth/signed-in`,
  silent_redirect_uri: `${window.location.origin}/auth/signed-in-silent`,
  scope: "openid profile role patientcontactfrontend custom",
};

export default oidcConfiguration;
