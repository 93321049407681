import { UnexpectedErrorView } from "@collabodoc/component-library";
import React from "react";
import { Navigate, useRouteError } from "react-router-dom";
import { VIEWS } from "./enums/urls";
import { IssueContextProvider } from "./IssueContext";
import Root from "./Root";
import ChooseCareCenterView from "./views/ChooseCareCenter";
import CreateNewIssueView from "./views/CreateNewIssueView";
import IssueView from "./views/issue/IssueView";
import IssueListView from "./views/issuelist/IssueListView";
import StatisticsContainer from "./views/statistics/StatisticsContainer";

const ErrorBoundary = () => {
  const error = useRouteError();
  console.error(error);
  return <UnexpectedErrorView />;
};

export const routes = [
  {
    path: "/",
    Component: Root,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        Component: () => <Navigate to={VIEWS.CARECENTERS} />,
      },
      {
        path: VIEWS.CARECENTERS,
        children: [
          {
            index: true,
            Component: ChooseCareCenterView,
          },
          {
            path: VIEWS.CARECENTER,
            Component: IssueContextProvider,
            children: [
              {
                index: true,
                Component: () => <Navigate to={VIEWS.ISSUES} />,
              },
              {
                path: VIEWS.STATISTICS,
                Component: StatisticsContainer,
              },
              {
                path: VIEWS.ISSUES,
                children: [
                  {
                    index: true,
                    Component: IssueListView,
                  },
                  {
                    path: VIEWS.ISSUE,
                    Component: IssueView,
                  },
                  {
                    path: VIEWS.CREATE_NEW_ISSUE,
                    Component: CreateNewIssueView,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
