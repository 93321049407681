import React, {useEffect, useRef} from "react";
import {SENDER} from "../../enums/patientcontactenums";
import ChatMessage from "./ChatMessage";

const ChatContainer = ({issue, isLoading}) => {
    const currentReceiverName = `${issue.issueOwner.firstName} ${issue.issueOwner.lastName}`
    const ref = useRef(null);

    useEffect(() => {
        if (!isLoading && issue.messages.length > 1) {
            if (ref) {
                ref.current.scrollTop = ref.current.scrollHeight;
            }
        }
    }, [issue, isLoading])
    
    return (
        <div ref={ref} className="chatContainer">
            {!isLoading && issue.messages.map((message) =>
                <ChatMessage message={message}
                             key={message.messageId}
                             currentSender={SENDER.CAREGIVER}
                             currentReceiverName={currentReceiverName}
                             careCenterId={issue.careCenterId}
                             issueId={issue.issueId}
                             timeOfRead={message.timeOfRead}/>)
            }
        </div>
    );
}
export default ChatContainer;