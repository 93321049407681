import { OidcProvider, OidcSecure } from "@axa-fr/react-oidc";
import { collabodocTheme, LoadingView } from "@collabodoc/component-library";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faCheck,
  faExclamationTriangle,
  faFilter,
  faPaperPlane,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { IntlProvider } from "react-intl";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { CustomSessionLost } from "./CustomSessionLost";
import oidcConfiguration from "./OidcConfiguration";
import { routes } from "./routes";
import { getTranslations, LOCALE } from "./utils/localeUtils";

library.add(
  faPaperPlane,
  faCheck,
  faExclamationTriangle,
  faVideo,
  faEnvelope,
  faFilter
);
const router = createBrowserRouter(routes);

const App = () => (
  <IntlProvider locale={LOCALE} messages={getTranslations()}>
    <div
      className="body-content theme-default patientcontact"
      id="maincontainer"
    >
      <ThemeProvider theme={collabodocTheme}>
        <OidcProvider
          configuration={oidcConfiguration}
          authenticatingComponent={LoadingView}
          callbackSuccessComponent={LoadingView}
          sessionLostComponent={CustomSessionLost}
          loadingComponent={LoadingView}
        >
          <OidcSecure>
            <RouterProvider router={router} />
          </OidcSecure>
        </OidcProvider>
      </ThemeProvider>
    </div>
  </IntlProvider>
);

export default App;
