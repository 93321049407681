
import React from 'react';
import { Tab, Nav, Col, Row } from 'react-bootstrap';
import IssueStatisticsOne from './IssueStatisticsOne';
import IssueStatisticsTwo from './IssueStatisticsTwo';
import PatientStatistics from './PatientStatistics';
import { StatisticsView } from '../../enums/statisticsenums';
import { FormattedMessage } from "react-intl";
import { useWindowWidth } from '@react-hook/window-size'
import styled from 'styled-components';
import PeriodSelector from './PeriodSelector';
import { H2, H4 } from '@collabodoc/component-library';
import dayjs from 'dayjs';



const Statistics = ({
    issueStatistics,
    initialStartDate,
    setStartDate,
    setEndDate,
    currentStartDate,
    currentEndDate,
    currentEventKey,
    setCurrentEventKey,
    years,
    selectedYear,
    setSelectedYear }) => {

    const handleView = () => {
        var today = dayjs();
        const currentYear = today.year();
        setStartDate(`${currentYear}-01-01`);
        setEndDate(today);
        setSelectedYear(currentYear);
        setCurrentEventKey(StatisticsView.Issues_2);
    }

    let width = useWindowWidth();
    const isDesktop = width >= 992;

    if (!isDesktop) {
        return <H2><FormattedMessage id='smallscreeninfo'/></H2>
    }

    return (
        <>
            <Row>
                <Col xs={12} md={{ span: 4, offset: 8 }} className="rangeDiv">
                    {currentEventKey !== StatisticsView.Issues_2 ?
                        <PeriodSelector
                            years={years}
                            selectedYear={selectedYear}
                            setStartDate={setStartDate}
                            currentEndDate={currentEndDate}
                            setEndDate={setEndDate}
                            currentStartDate={currentStartDate}
                            initialStartDate={initialStartDate}
                            setSelectedYear={setSelectedYear}
                        />
                        : <PeriodSelectorText><FormattedMessage id='showingdatafor' values={{selectedYear: selectedYear}}/></PeriodSelectorText>}
                </Col>
            </Row>
            <Tab.Container defaultActiveKey={currentEventKey}>
                <Row>
                    <Col xs={12}>
                        <Nav variant="tabs" className="tabcontainer">
                            <Nav.Item>
                                <Nav.Link onClick={() => setCurrentEventKey(StatisticsView.Issues_1)} eventKey={StatisticsView.Issues_1}><FormattedMessage id='issuestatistics1' /></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => handleView()} eventKey={StatisticsView.Issues_2}><FormattedMessage id='issuestatistics2' /></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => setCurrentEventKey(StatisticsView.Patient)} eventKey={StatisticsView.Patient}><FormattedMessage id='patientstatistics' /></Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Tab.Content>
                    <StatisticsContainer>
                        {currentEventKey === StatisticsView.Issues_1 &&
                            <Tab.Pane eventKey={StatisticsView.Issues_1} >
                                <IssueStatisticsOne issueStatistics={issueStatistics} />
                            </Tab.Pane>}

                        {currentEventKey === StatisticsView.Issues_2 &&
                            <Tab.Pane eventKey={StatisticsView.Issues_2} >
                                <IssueStatisticsTwo issueStatistics={issueStatistics} setStartDate={setStartDate} setEndDate={setEndDate} setSelectedYear={setSelectedYear}/>
                            </Tab.Pane>}

                        {currentEventKey === StatisticsView.Patient &&
                            < Tab.Pane eventKey={StatisticsView.Patient}>
                                <PatientStatistics issueStatistics={issueStatistics} />
                            </Tab.Pane>}

                    </StatisticsContainer>
                </Tab.Content>
            </Tab.Container>
        </>
    );
};

export default Statistics;



const StatisticsContainer = styled.div`
    height: 800px;
    background-color: #e1e1e1;
    border: 1px solid #e1e1e1;
`;

const PeriodSelectorText = styled(H4)`
    margin-top: 10px;
    float: right;
    color: red !important;
`;


