import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Gender, IsChild, StatisticsTheme } from "../../enums/statisticsenums";
import { NivoContainer } from "./NivoContainer";

const PatientStatistics = ({
  issueStatistics: { selfOrOther = [], genderDivision = [], ages = [] },
}) => {
  const isChildData = selfOrOther.map(({ key, value }) => {
    return { id: IsChild[key], value: value };
  });

  const genderData = genderDivision.map(({ key, value }) => {
    return { id: Gender[key], value: value };
  });

  const ageData = ages.map(({ key, value }) => {
    return { age: key, value: value };
  });

  return (
    <>
      <UpperDiv>
        <Row>
          <Col xs={12} sm={6}>
            <NivoContainer height={"350px"} left>
              <h5 className="statisticsLabel">
                <FormattedMessage id="patientsperage" />
              </h5>
              <ResponsiveBar
                data={ageData}
                indexBy="age"
                margin={{ top: 60, right: 70, bottom: 50, left: 70 }}
                padding={0.3}
                valueScale={{ type: "linear" }}
                colors="#ffe150"
                labelTextColor="black"
                tooltip={({ indexValue, value }) => (
                  <span>
                    {indexValue}: <b>{value}</b>
                  </span>
                )}
              />
            </NivoContainer>
          </Col>
          <Col xs={6} sm={3}>
            <NivoContainer height={"350px"}>
              <h5 className="statisticsLabel">
                <FormattedMessage id="childadultdivision" />
              </h5>
              <ResponsivePie
                innerRadius={0.5}
                padAngle={0.5}
                startAngle={-90}
                colors={StatisticsTheme}
                cornerRadius={5}
                enableRadialLabels={false}
                data={isChildData}
                margin={{ top: 45, right: 40, bottom: 80, left: 40 }}
                arcLabelsTextColor="white"
                legends={[
                  {
                    anchor: "bottom",
                    direction: "row",
                    justify: false,
                    translateX: 25,
                    translateY: 50,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemsSpacing: 0,
                    symbolSize: 30,
                    itemDirection: "left-to-right",
                  },
                ]}
              />
            </NivoContainer>
          </Col>
          <Col xs={6} sm={3}>
            <NivoContainer height={"350px"} right>
              <h5 className="statisticsLabel">
                <FormattedMessage id="genderdivision" />
              </h5>
              <ResponsivePie
                innerRadius={0.5}
                padAngle={0.5}
                startAngle={-90}
                colors={StatisticsTheme}
                cornerRadius={5}
                enableRadialLabels={false}
                data={genderData}
                margin={{ top: 45, right: 40, bottom: 80, left: 40 }}
                arcLabelsTextColor="white"
                legends={[
                  {
                    anchor: "bottom",
                    direction: "row",
                    justify: false,
                    translateX: 15,
                    translateY: 50,
                    itemWidth: 70,
                    itemHeight: 20,
                    itemsSpacing: 10,
                    symbolSize: 30,
                    itemDirection: "left-to-right",
                  },
                ]}
              />
            </NivoContainer>
          </Col>
        </Row>
      </UpperDiv>
      <div className="lowerdiv">
        <Row>
          <Col xs={12} sm={6}>
            {/* <NivoContainer height={"350px"} left>
                            <h4 className="statisticsLabel">Antal ärenden per vårdpersonal</h4>
                            <ResponsiveBar
                                data={userdata}
                                indexBy="name"
                                margin={{ top: 50, right: 30, bottom: 50, left: 110 }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                colors={StatisticsTheme}
                                labelTextColor="white"
                                layout="horizontal"
                                enableGridY={false}
                                enableGridX={true}
                            />
                        </NivoContainer> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PatientStatistics;

const UpperDiv = styled.div`
  margin-top: 20px;
`;
