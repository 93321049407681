import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { TextButton, useGetBlob } from "@collabodoc/component-library";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useParams } from "react-router-dom";
import { API_URLS } from "../../enums/urls";

export const AttachmentsList = ({ issueId, attachments }) => {
  const { accessToken } = useOidcAccessToken();
  const { careCenterId } = useParams();
  const { doGetBlob } = useGetBlob(null, accessToken);

  const downloadFile = (fileId, fileName) =>
    doGetBlob(API_URLS.ISSUE_DOWNLOAD_FILE(careCenterId, fileId, issueId)).then(
      (blob) => {
        const file = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = file;
        link.download = fileName;
        link.click();
      }
    );

  return (
    attachments.length > 0 && (
      <div className={"attachment-section"}>
        {attachments.map(({ fileId, fileName }) => (
          <div key={fileId} className={"smallText attachment-text"}>
            <FontAwesomeIcon icon={faPaperclip} />
            <TextButton onClick={() => downloadFile(fileId, fileName)}>
              {fileName}
            </TextButton>
          </div>
        ))}
      </div>
    )
  );
};
