import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  LoadingView,
  SecondaryButton,
  SuccessButton,
  useGet,
  usePut,
} from "@collabodoc/component-library";
import { faAngleLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TourProvider } from "@reactour/tour";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import steps from "../../Content/Tutorials/tutorial-1_5-steps";
import { TUTORIAL_COOKIES } from "../../enums/tutorial-cookies";
import { API_URLS } from "../../enums/urls";
import AssessmentCard from "./AssessmentCard";
import ChatCard from "./ChatCard";
import CloseIssueModal from "./CloseIssueModal";
import ContactPersonCard from "./ContactPersonCard";
import IssueDetailsCard from "./IssueDetailsCard";
import IssueSettingsCard from "./IssueSettingsCard";

function IssueViewContent() {
  const navigate = useNavigate();
  const { accessToken } = useOidcAccessToken();
  const intl = useIntl();
  const { careCenterId, issueId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const override = searchParams.get("override") || false;
  const [showCloseIssueModal, setShowCloseIssueModal] = useState(false);

  const {
    doGet: reFetchIssue,
    response: issue,
    isLoading,
  } = useGet(API_URLS.ISSUE(careCenterId, issueId, override), accessToken);
  const { doPut: resetOpen } = usePut(
    API_URLS.ISSUE_RESET_OPEN(careCenterId, issueId),
    accessToken
  );
  const { doPut: setTreatmentUsers, response: userRole } = usePut(
    API_URLS.ISSUE_TREATMENT_USERS(careCenterId, issueId),
    accessToken
  );

  useEffect(() => {
    setTreatmentUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    resetOpen().then(() => {
      navigate("..");
    });
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBack);
    return () => {
      window.removeEventListener("beforeunload", handleBack);
    };
  }, []);

  const toggleCloseissueModal = () =>
    setShowCloseIssueModal(!showCloseIssueModal);
  const hideCloseIssueModal = () => {
    setShowCloseIssueModal(false);
  };

  if (!issue || !userRole) {
    return <LoadingView />;
  }

  return (
    <div className="marginBottom">
      <Row>
        <Col lg={{ span: 3 }}>
          <IssueDetailsCard issue={issue} />
          <ContactPersonCard issue={issue} />
          <IssueSettingsCard issue={issue} />
        </Col>
        <Col lg={{ span: 5 }}>
          <ChatCard
            issue={issue}
            isLoading={isLoading}
            reFetchIssue={reFetchIssue}
          />
        </Col>
        <br />
        <Col lg={{ span: 4 }}>
          <AssessmentCard issue={issue} />
        </Col>
      </Row>
      <nav className="navbar fixed-bottom navbar-bottom">
        <div className="floatLeft dispInline">
          <SecondaryButton type={"button"} onClick={handleBack}>
            <FontAwesomeIcon icon={faAngleLeft} />
            <span>
              <FormattedMessage id={"returntoissuelist"} />
            </span>
          </SecondaryButton>
        </div>
        {!issue.isClosed && (
          <div className="floatRight dispInline">
            <SuccessButton type={"button"} onClick={toggleCloseissueModal}>
              <FontAwesomeIcon icon={faCheck} size="sm" />
              <span>
                {intl.formatMessage({ id: "closeissuemodal.closeissuebutton" })}
              </span>
            </SuccessButton>
            <CloseIssueModal
              showCloseIssueModal={showCloseIssueModal}
              hideCloseIssueModal={hideCloseIssueModal}
              toggleCloseissueModal={toggleCloseissueModal}
              issue={issue}
            />
          </div>
        )}
      </nav>
    </div>
  );
}

const IssueView = () => {
  return (
    <>
      <TourProvider
        steps={steps}
        showBagde={false}
        beforeClose={() => Cookies.set(TUTORIAL_COOKIES.LATEST, "true")}
      >
        <IssueViewContent />
      </TourProvider>
    </>
  );
};

export default IssueView;
