import { SecondaryButton } from "@collabodoc/component-library";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

const ContactPersonCard = ({ issue }) => {
  const ssnRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const intl = useIntl();

  const tenNumberSSN =
    issue.issueOwner.personalNumber &&
    issue.issueOwner.personalNumber.substring(2);
  const age = issue.issueOwner.ageInMonths
    ? `${issue.issueOwner.ageInMonths} ${intl.formatMessage({ id: "month" })}`
    : `${issue.issueOwner.ageInYears} ${intl.formatMessage({ id: "year" })}`;

  return (
    <Card>
      <Card.Header>
        <h4 className="dispInline">
          <FormattedMessage id={"contactpersoncard.header"} />
        </h4>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col s={12} xs={12}>
            <strong>
              <FormattedMessage id={"contactpersoncard.patient"} />
            </strong>
            <p>
              <FormattedMessage id={"name"} />:{" "}
              {`${issue.issueOwner.firstName} ${issue.issueOwner.lastName}`}
            </p>
            <p>
              <FormattedMessage id={"age"} />: {age}
            </p>
            <div>
              <p className="dispInline">
                <FormattedMessage id={"personalnumber"} />:{" "}
                {issue.issueOwner.personalNumber}
              </p>
              <span hidden ref={ssnRef}>
                {tenNumberSSN}
              </span>
              &nbsp;
              <SecondaryButton
                type={"button"}
                onClick={() => navigator.clipboard.writeText(tenNumberSSN)}
              >
                <FontAwesomeIcon icon={faCopy} />
              </SecondaryButton>
            </div>
            {!issue.selfRegistration && (
              <>
                <br />
                <strong>
                  <FormattedMessage id={"contactperson"} />
                </strong>
                <p>
                  <FormattedMessage id={"name"} />:{" "}
                  {`${issue.contactPerson.firstName} ${issue.contactPerson.lastName}`}
                </p>
              </>
            )}
            <div>
              <p className="dispInline">
                <FormattedMessage id={"phonenumber"} />:{" "}
                <span ref={phoneNumberRef}>
                  {issue.contactPerson.phoneNumber}{" "}
                </span>
              </p>
              <SecondaryButton
                type={"button"}
                onClick={() =>
                  navigator.clipboard.writeText(issue.contactPerson.phoneNumber)
                }
              >
                <FontAwesomeIcon icon={faCopy} />
              </SecondaryButton>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default ContactPersonCard;
