import React from "react";

const steps = [
    {
        selector: '.step0',
        content: () =>
            (<div>
                <h4>Bifoga filer</h4>
                <p>I den senaste versionen av digital kontakt har vi infört möjligheten att bifoga filer till meddelanden.
                    Både för vårdpersonalen och patienten.</p>
            </div>),
        position: 'center'
    },
    {
        selector: '.tut-attach-files',
        content: `Klicka på knappen för att bifoga filer till patienten.`
    },
    {
        selector: '.tut-enable-attachments',
        content: `Kryssa i rutan om du vill att patienten ska kunna bifoga filer med sina meddelanden.`
    }];

export default steps;